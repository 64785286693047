export enum MenuIcon {
  dashboard = 'dashboard',
  emergency = 'emergency',
  indicators = 'indicators',
  insights = 'insights',
  machinesSummary = 'machinesSummary',
  reports = 'reports',
  summary = 'summary',  
  agilean = 'agilean',
  curves = 'curves',
  textInsights = 'textInsights',
  collaboratorsLink = 'collaboratorsLink',
  displacement = 'displacement',
  realtime = 'realtime',
  calibration = 'calibration',
  onePage = 'onePage',
  support = 'support',
  incidents = 'incidents',
  scannerstatus = 'scannerstatus',
  users = 'users',
  newpassword = 'newpassword',
  clientregistration = 'clientregistration',
  homepage = 'homepage',
  infleet = 'infleet',
  logs = 'logs',
  resourcessummary = 'resourcessummary',
}
