import React from 'react';

import IconUsers from '../../../../../Icons/Users';
import { SelectOption } from '../../../../../components/Select/types';
import AreaMultiSelect from '../../../../filters/AreaMultiSelect';
import { useAuthContext } from '../../../../../stores';
import DynamicComponentsFactory from '../../../../../components/DynamicComponents/DynamicComponentsFactory';
import { useAuth } from '../../../../../hooks';
import { getInOutTooltipContent } from './InOutTooltipContent';
import { IconContainer } from '../../../styles';
import { Info } from '../../../../../components/Info';

import * as S from '../styles';

type InOutChartHeaderProps = {
  areaSelected: SelectOption[];
  setAreaSelected: (value: SelectOption[]) => void;
  exportDataToXlsx: (data: any[]) => any[];
  data: any;
};

export const InOutChartHeader: React.FC<InOutChartHeaderProps> = (props: InOutChartHeaderProps) => {
  const {authData} = useAuthContext();
  const charts = authData ? authData.components.charts : [];
  const { areaSelected, setAreaSelected, data, exportDataToXlsx } = props;
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getInOutTooltipContent(clientId);

  return (
    <S.Header>
      <S.Titlecontainer>
        <S.TitleContent>
          <IconUsers/>
          <S.Title>Chegada/Saída das áreas produtivas</S.Title>
          <IconContainer>
            <Info 
              content={tooltipContent} 
              position={'right'} 
              burndown={false}
              screen={'Prod'}
              eventName={'EntrSaid'}
            />
          </IconContainer>
        </S.TitleContent>
        <DynamicComponentsFactory charts={charts} component='inoutExport'/>
      </S.Titlecontainer>
      <S.Filtercontainer>
        <AreaMultiSelect
          itemsSelected={areaSelected}
          setItemsSelected={setAreaSelected}
          screen={'Indicadores - Produtividade - Chegada/Saída'}
		  eventName={'Indic>ProdChegadasEsaidas'}
        />
      </S.Filtercontainer>
    </S.Header>
  );
};
