import React from "react";

import { ContentContainer } from "../../components/PageStructure/styles";
import { Page } from "../../components/PageStructure/Page";
import { ResourcesMetricsOptions } from "./components/ResourcesMetricsOptions";
import { ResourcesMetricsContextProvider } from "./context/ResourcesMetricsContext";
import { ResourcesTotalVsMonitored } from "./components/ResourcesTotalVsMonitored";
import { ResourcesTimeDivisionBars } from "./components/ResourcesTimeDivisionBars";

export const ResourcesMetricsSummaryContainer: React.FC<{}> = () =>{ 
    return (
        <Page title="Relatório de recursos"
            components={<ContentContainer value="">
                            <ResourcesMetricsContextProvider>
                                <ResourcesMetricsOptions />
                                <ResourcesTotalVsMonitored />
                                <ResourcesTimeDivisionBars />
                            </ResourcesMetricsContextProvider>
                        </ContentContainer> }
        />
    );
  }
  