import React, { useEffect } from 'react';

import { useProductivityContext } from './context/PageProductivityContext';
import { useAuthContext } from '../../stores/AuthContext';
import CompanySelect from '../filters/CompanySelect';
import ProfessionSelect from '../filters/ProfessionSelect';
import { DateRangePicker } from '../../components';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { getFollowingFriday } from '../../utils/DateTime';
import { useAuth } from '../../hooks/useAuth';

import { OptionsContainer } from '../../components/PageStructure/styles';
import * as S from '../../styles/structure';
import CompanyMultiSelect from '../filters/CompanyMultiSelect';
import ProfessionMultiSelect from '../filters/ProfessionMultiSelect';

const ProductivityOptions: React.FC = () => {
  const {
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    updatePeriod,
  } = useProductivityContext();
  const { authData, monitorAgent } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Indicadores - Produtividade', userName, authData, 'Indic>abaProdutividade'),[]);

  return (
    <OptionsContainer>
      {compIds != 'a' && !compIds.includes(',') ? (
        <></>
      ) : (
        <S.SimpleContainer>
          <CompanyMultiSelect 
            itemsSelected={companySelected}
            setItemsSelected={setCompanySelected}
            screen={`Indicadores - Produtividade`}
			eventName={'indicProd'}
          />
        </S.SimpleContainer>
      )}
      <S.SimpleContainer>
        <ProfessionMultiSelect 
            itemsSelected={professionSelected}
            setItemsSelected={setProfessionSelected}
            screen={`Indicadores - Produtividade`}
			eventName={'indicProd'}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Indicadores"
          maxDate={getFollowingFriday(new Date())}
          component="Produtividade -"
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};

export default ProductivityOptions;
