import React from 'react';
import IconUsers from '../../../../Icons/Users';
import AreaSelect from '../../../filters/AreaSelect';

import * as S from '../styles';

type ProdVSDisChartHeaderProps = {
  areaSelected: number;
  setAreaSelected: (value: number) => void;
};

export const ProductivityVSDisplacementChartHeader: React.FC<ProdVSDisChartHeaderProps> = (props: ProdVSDisChartHeaderProps) => {
  const { areaSelected, setAreaSelected } = props;

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Produtividade VS Deslocamento</S.Title>
      </S.Titlecontainer>
      <S.Filtercontainer>
        <AreaSelect areaSelected={areaSelected} setAreaSelected={setAreaSelected} screen={'Deslocamentos'} eventName={'Indic>deslocProdXdesloc'}/>
      </S.Filtercontainer>
    </S.Header>
  );
};