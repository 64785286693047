import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { SelectOption } from '../../../components/Select/types';
import { Planning } from '../../indicators/types/Indicators';
import { parseToDateFormatted } from '../../../utils/DateTime';

type ResourcesMetricsContextProps = {
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  initialDate: any;
  finalDate: any;
  date: any;
  setCompanySelected: (company: SelectOption[]) => void;
  setProfessionSelected: (profession: SelectOption[]) => void;
  updatePeriod: (initial: any, final: any) => void;
  clearFilters: () => void;
  setDate: (value: any) => void;
};

type Props = {
  children: ReactNode;
};

const ResourcesMetricsContext = createContext<ResourcesMetricsContextProps>({} as ResourcesMetricsContextProps);

export function ResourcesMetricsContextProvider(props: Props) {
  const { children } = props;
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [professionSelected, setProfessionSelected] = useState<SelectOption[]>([]);
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);
  const [date, setDate] = useState(parseToDateFormatted(new Date(), new Date()));
  

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const clearFilters = () => {
    setCompanySelected([]);
    setProfessionSelected([]);
    updatePeriod(moment(), moment());
    setDate(parseToDateFormatted(new Date(), new Date()));
  }

  const value = {
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
    clearFilters,
    date,
    setDate,
  };

  return <ResourcesMetricsContext.Provider value={value}>{children}</ResourcesMetricsContext.Provider>;
}

export function useResourcesMetricsContext() {
  const context = useContext(ResourcesMetricsContext);

  if (typeof context === 'undefined') {
    throw new Error('ResourcesMetricsContext must be used within an ResourcesMetricsContext');
  }

  return context;
}
