import React from 'react';

import { MonitoredMap } from '../../../components/MonitoredMap';
import { NotificationBoard } from '../components/NotificationBoard';
import { Evacuation } from '../components/Evacuation';
import { useMapArea } from '../../../components/MonitoredMap/hooks/useMapArea';
import { EmergencyMapComponents } from '../components/EmergencyMapComponents';
import { useEvacuationContext } from '../context/EvacuationContext';
import { useAuthContext } from '../../../stores';

export const EmergencyMapContainer: React.FC<{}> = () => {
  const {authData} = useAuthContext();
  const { data } = useEvacuationContext();
  const { mapAreas } = useMapArea(true, data.mode, data.dangerAreas, data.safeArea);
  const user = localStorage.getItem('@trackfy_user_id');
  const userId = user ? Number.parseInt(user) : 1;
  const charts = authData ? authData.components.charts : [];
  const showEvacuation = ( charts.findIndex((chart: string) => chart === "evacuation") >= 0 );

  const childComponents = [
    <EmergencyMapComponents key="emergencyMapComponents"
                            mapAreas={mapAreas}
                            mode={data.mode}
                            showEvacuation={showEvacuation}
                            dangerAreas={data.dangerAreas}
                            safeArea={data.safeArea} />,
    <NotificationBoard key="emergencyNotificationBoard" />
  ];
  
  if(showEvacuation)
    childComponents.push(<Evacuation key="evacuation"
                                     isEvacuating={data.mode === "standby"}
                                     requestedAreas={mapAreas.requestedAreas}
                                     userId={userId} />);
  
  return ( <MonitoredMap monitoredComponent="emergency" childComponents={childComponents} eventName={'paginaEmergencia'}/> );
};
