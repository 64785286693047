import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getMonitoredResources } from '../../services/api/TrackfyService';
import { MonitoredResources } from '../../types/Filters';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { MultiSelect } from '../../components/MultiSelect';
import { MultiSelectType } from './types';
import { parseSelectOptions } from '../../utils/Convert';
import { DropdownIndicator } from '../../components/MultiSelect/DropdownIndicator';
import { MultiSelectStyles } from '../../components/MultiSelect/styles';

type MonitoredResourcesSelectProps = {
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  monitoredResourcesSelected: SelectOption[];
  setMonitoredResourcesSelected: (value: SelectOption[]) => void;
  screen?: string;
  eventName: string;
};

export const MonitoredResourcesMultiSelect: React.FC<MonitoredResourcesSelectProps> = (
  props: MonitoredResourcesSelectProps
) => {
  const [monitoredResources, setMonitoredResources] = useState<MultiSelectType>({selectOptions:[], loading:true});
  const [isLoading, setIsLoading] = useState(true);
  const { getClientId, USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const {
    monitoredResourcesSelected,
    setMonitoredResourcesSelected,
    companySelected,
    professionSelected,
    screen,
	eventName
  } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getMonitoredResources(getClientId(), parseSelectOptions(companySelected), parseSelectOptions(professionSelected), currentRequest.token)
        .then((result: AxiosResponse<any>) => {
          const options: SelectOption[] = result.data.map((resources: MonitoredResources) => {
            return { label: resources.name, value: resources.id ? resources.id.toString() : '0' }
          });

          setMonitoredResources({selectOptions: options, loading: false});
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getMonitoredResourcessApi request canceled by the user.');
    };
  }, [companySelected, professionSelected]);

  const handleChange = (selected: any): void => {
    setMonitoredResourcesSelected(selected as SelectOption[]);

    const label = selected.map((item:SelectOption) => item.label);

     if (label && label.length > 0)
      mixpanelTrack(monitorAgent, `${screen} - Recurso ${label} selecionado`, userName, authData, `${eventName}>filtRercur${label}`);
  };

  return (
    <MultiSelect
      id='moviment-resources-select'
      name='moviment-resources-select'
      placeholder='Selecione um recurso'
      options={monitoredResources.selectOptions}
      isLoading={monitoredResources.loading}
      dropdownIndicator={{DropdownIndicator}}
      styles={MultiSelectStyles}
      onChange={handleChange}
      currentValue={monitoredResourcesSelected}
      isMulti={true}
    />
  );
};