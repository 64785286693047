import React, { useEffect, useRef, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { ReportIcon } from '../../../Icons/Report';
import { TooltipContent } from '../../../components/Info/Tooltip';
import { Info } from '../../../components/Info';
import { useResourcesMetricsContext } from '../context/ResourcesMetricsContext';
import { getAvailableVsMonitoredResources } from '../../../services/api/TrackfyService/integration';
import { parseSelectOptions } from '../../../utils/Convert';
import { AvailableVsMonitoredData } from '../types';
import { IconLoading } from '../../../components/IconLoading';
import { Modal } from '../../../components/Modal';
import { IconExport } from '../../../Icons/Export';
import { useReactToPrint } from 'react-to-print';
import { ReportsHeader } from './ReportsHeader';

import * as S from '../styles';
import '../stylesheet.css';

export const ResourcesTotalVsMonitored: React.FC = () => {
  const [dataMetrics, setDataMetrics] = useState<AvailableVsMonitoredData[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const { companySelected, professionSelected, initialDate, finalDate } = useResourcesMetricsContext();
  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      if(showPreview) {
        setLoading(true);
      
        getAvailableVsMonitoredResources(
          parseSelectOptions(companySelected),
          parseSelectOptions(professionSelected),
          (new Date(initialDate.toString())).getTime(),
          (new Date(finalDate.toString())).getTime(),
          currentRequest.token
        ).then((response: AxiosResponse<AvailableVsMonitoredData[]>) => {
          setDataMetrics(response.data);
          setLoading(false);
        })
      } else {
        setLoading(false);
        setDataMetrics([]);
      }
    }

    return () => {
      mounted = false;
      currentRequest.cancel('ResourcesTimeDivision request canceled by the user.');
    };
  }, [showPreview]);

  const toggleExpand = () => {
    setShowPreview(!showPreview);
  };
  
  const calculatePercentage = (disponivel: number, medido: number) =>
    disponivel === 0 ? '0%' : `${((medido / disponivel) * 100).toFixed(2)}%`;

  const getReportsTooltipContent = (): TooltipContent[] => 
     [ { content: 'Comparativo entre disponível e medido com divisão por empresas e funções!' } ];

  const handlePrint = useReactToPrint({
    content: () => {
      const content = ref.current?.cloneNode(true);
      const printEl = document.createElement('div');
      if (content) printEl.appendChild(content);
      return printEl;
    },
  });
  
  return (
    <S.ResourceCard loading={loading} showPreview={showPreview}>
      <S.SubtitleHeader>
        <S.HeaderText>
          <ReportIcon />
          Recursos: Disponível vs Medido
        </S.HeaderText>
        <Info 
          content={getReportsTooltipContent()} 
          position={'right'} 
          burndown={false}
          screen={'Relat'}
          eventName={''}
        />
        <S.GenerateButton title='Exportar' onClick={() => toggleExpand()} icon={<IconExport/>} />
      </S.SubtitleHeader>

      {showPreview && (
        <Modal visible={showPreview}>
          <S.ModalTableContainer>
            <S.ModalHeaderContainer>
            {!loading && <S.ToggleButton onClick={handlePrint}> {'Export PDF'}</S.ToggleButton>}
              <S.ToggleButton onClick={toggleExpand}> {'X close'}</S.ToggleButton>
            </S.ModalHeaderContainer>
            <S.TableContainer loading={loading} ref={ref}>
              {loading &&
                  <S.LoadingContainer>
                      <IconLoading />
                  </S.LoadingContainer>
              }
              {!loading && dataMetrics.map((dia, index) => (
                <div key={dia.data + "_container"} className="pagebreak">
                  <div key={dia.data + "_header"}>
                    <ReportsHeader title="Recursos: Disponível vs Medido" margin="3rem !important" />
                  </div>
                  <S.TableWrapper key={index}>
                    <S.DataHeader>{dia.data}</S.DataHeader>
                    <S.Table>
                      <thead>
                        <tr>
                          <S.Th>Função</S.Th>
                          <S.Th>Disponível</S.Th>
                          <S.Th>Medido</S.Th>
                          <S.Th>%</S.Th>
                        </tr>
                      </thead>
                      <tbody>
                        {(Object.entries(dia.empresas).length > 0) && 
                          Object.entries(dia.empresas).map(([empresa, funcoes], i) => {
                          const totalDisponivel = funcoes.reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0);
                          const totalMedido = funcoes.reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0);
                          const totalPorcentagem = calculatePercentage(totalDisponivel, totalMedido);

                          return (
                            <React.Fragment key={i}>
                              {i > 0 && <S.SectionGap />}
                              <S.SectionHeader>
                                <S.Td colSpan={4}>  {empresa}</S.Td>
                              </S.SectionHeader>
                              {funcoes.map((func, idx) => (
                                <S.Row loading={loading} key={idx} isEven={idx % 2 === 0}>
                                  <S.Td>{func.funcao}</S.Td>
                                  <S.Td>{func.disponivel}</S.Td>
                                  <S.Td>{func.medido}</S.Td>
                                  <S.Td>{calculatePercentage(func.disponivel, func.medido)}</S.Td>
                                </S.Row>
                              ))}
                              <S.FooterRow>
                                <S.Td>Total ({empresa})</S.Td>
                                <S.Td>{totalDisponivel}</S.Td>
                                <S.Td>{totalMedido}</S.Td>
                                <S.Td>{totalPorcentagem}</S.Td>
                              </S.FooterRow>
                            </React.Fragment>
                          );
                        })}
                      
                        <S.SectionGap />
                        <S.CompanyFooterRow>
                          <S.Td>Total ({dia.data})</S.Td>
                          <S.Td>
                            {Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0)}
                          </S.Td>
                          <S.Td>
                            {Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0)}
                          </S.Td>
                          <S.Td>
                            {calculatePercentage(
                              Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.disponivel.toString()), 0),
                              Object.values(dia.empresas).flat().reduce((acc, curr) => acc + parseInt(curr.medido.toString()), 0)
                            )}
                          </S.Td>
                        </S.CompanyFooterRow>
                      </tbody>
                    </S.Table>
                  </S.TableWrapper>
                </div>
              ))}
            </S.TableContainer>
          </S.ModalTableContainer>
        </Modal>
      )}

    </S.ResourceCard>
  );
};