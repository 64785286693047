import Axios, { CancelTokenSource } from 'axios';
import { useState } from 'react';

import { useAuth } from '../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../stores/AuthContext';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { solveIncident } from '../../../../../services/api/TrackfyService';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';
import { SolveIncidents } from '../../../types/Incidents';
import { notification } from '../../../../../utils/Notification';
import moment from 'moment';

export const useSolveIncident = (id?: string ) => {
  const [solvingIncident, setSolvingIncident] = useState<boolean>(false);
  const [comment, setComment] = useState('')
  const isMounted = useIsMounted();
  const { USER_NAME, getClientId } = useAuth();
  const { authData, monitorAgent } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);

  const handleCommentChange = (event: string) => {
    const newComment = event;
    setComment(newComment);
  };
  const characterCount = comment.length

  const isDefined = (): boolean => {
    if (characterCount > 0) return true;

    return false;
  };

  const handleSolveIncident = async () => {
    setSolvingIncident(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const updateIncidentRequest: SolveIncidents = {
      id: id,
      comment: comment,
      solvedBy: userName,
      currentDate: moment(new Date()).toISOString()
    };

    const serviceCreationResponse = await solveIncident(
      getClientId(),
      updateIncidentRequest,
      currentRequest.token
    )
    .then((response) => {
      notification('success', 'Incidente resolvido com sucesso');
      mixpanelTrack(monitorAgent, 'Log de Incidentes - Incidente resolvido', userName, authData, 'logDeIncid>Resolver');
    })
    .catch((err) => {
      console.log(err);
      notification('error', 'Ocorreu um erro ao resolver o incidente');
    });

    if (isMounted.current) setSolvingIncident(false);

    return true;
  };

  return {
    solvingIncident,
    handleSolveIncident,
    handleCommentChange,
    characterCount,
    comment,
    isDefined
  };
};
