import Axios, { CancelTokenSource } from 'axios';
import { useState } from 'react';

import { useAuth } from '../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../stores/AuthContext';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { deleteCollaborator } from '../../../../../services/api/TrackfyService';
import { DeleteCollaborators } from '../../../types/linkCollaborators';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';

export const useDeleteCollaborator = (tagAlias: string) => {
  const [deletingCollaborator, setDeletingCollaborator] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleDeleteCollaborator = async () => {
    setDeletingCollaborator(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const updateCollaboratorsRequest: DeleteCollaborators = {
      tagAlias,
    };

    const serviceCreationResponse = await deleteCollaborator(
      updateCollaboratorsRequest,
      currentRequest.token
    );

    //mixpanelTrack(monitorAgent, 'Vinculacao - Colaborador excluido', userName, authData);
	  mixpanelTrack(monitorAgent, 'Vinculacao - Colaborador' + tagAlias + 'desvinculado', userName, authData, 'Vinc>DeleteVinc');

    if (isMounted.current) setDeletingCollaborator(false);

    return true;
  };

  return {
    deletingCollaborator,
    handleDeleteCollaborator,
  };
};
