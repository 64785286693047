import React from 'react';
import Calendar from 'react-calendar';

import { useDateRangePicker, DateRangeProps } from './hooks/useDateRangePicker';
import {
  CalendarContainer,
  CalendarInputGroup,
  CalendarInput,
  CalendarGroup,
  CalendarOptsContainer,
  CalendarConfirm,
  CalendarCurrent,
  CalendarCancel,
} from './styles';
import 'react-calendar/dist/Calendar.css';

export const DateRangePicker: React.FC<DateRangeProps> = (props) => {
  const {
    updatePeriod,
    screen,
    component,
    maxDate,
    selectRange,
    showToday,
    loadDate,
    customIcon,
    id,
    inputStyle,
    containerStyle,
    limitDateRange,
    placeholder,
  } = props;

  const {
    date,
    calendar,
    setCalendar,
    handleClickToday,
    handleConfirmDate,
    onChange,
    updateCalendarState,
    toggleCalendar,
    getMaxDateLimited,
    getMinDateLimited,
    setCurrentInitialDateSelected,
  } = useDateRangePicker({
    updatePeriod,
    screen,
    component,
    maxDate,
    selectRange,
    showToday,
    loadDate,
    limitDateRange,
  });

  return (
    <CalendarContainer style={containerStyle}>
      <CalendarInputGroup onBlur={toggleCalendar} onClick={toggleCalendar}>
        <CalendarInput
          id={id}
          customIcon={customIcon}
          readOnly
          type="text"
          className="icon-calendar"
          value={date}
          style={inputStyle}
          placeholder={placeholder}
        />
      </CalendarInputGroup>
      {calendar && (
        <CalendarGroup onClick={() => updateCalendarState(true)} onBlur={() => updateCalendarState(false)}>
          <Calendar
            locale="pt-BR"
            maxDate={limitDateRange ? getMaxDateLimited() : new Date()}
            minDate={limitDateRange ? getMinDateLimited(limitDateRange) : getMinDateLimited(30)}
            className="input-calendar"
            onChange={onChange}
            selectRange
            onClickDay={(v) => {
              if(limitDateRange)
                setCurrentInitialDateSelected(v);
              updateCalendarState(false);
            }}
          />
          <CalendarOptsContainer>
            <CalendarCancel onClick={() => setCalendar(!calendar)}>
              <span>Cancelar</span>
            </CalendarCancel>
            <CalendarCurrent onClick={handleClickToday}>
              <span>Hoje</span>
            </CalendarCurrent>
            <CalendarConfirm onClick={handleConfirmDate}>
              <span>Ok</span>
            </CalendarConfirm>
          </CalendarOptsContainer>
        </CalendarGroup>
      )}
    </CalendarContainer>
  );
};
