import { getConfig } from '../../../config/app.config';
import { ConfigKey } from '../../../enum/ConfigKey';
import { MenuPermission, UserGroupRoute } from '../../../types';
import * as integration from './integration';
import * as mock from './mock';

const selectedModule = getConfig(ConfigKey.REACT_APP_USE_MOCKS) === 'true' ? mock : integration;

/*export type IRoutes = {
	key: string;
	path: string;
}

export type IMenu = {
	key: string;
	description: string;
	icon: string;
	path: string;
}*/

export type IAuthProfilePayload = {
  userId: number;
  clientId: number;
  groupId: number;
  companyId:number;
  grpCompanies:string;
  name: string;
  email: string;
  showSurvey: boolean;
  persona: string;
  mainPage: string;
	routes: UserGroupRoute[];
	menu: MenuPermission[];
  components: string[];
  hasMap: boolean;
  registered: boolean;
  isTrackfy?: boolean;
};

export type IAuthProfileAuthorization = {
  superUser: boolean;
  admin: boolean;
  rules: string[];
};

export type IAuthResponse = {
  access_token: string;
  refresh_token: string;
  profileData: IAuthProfilePayload;
  authorization: IAuthProfileAuthorization;
};

export type IAuthRequest = {
  user: string;
  password: string;
  recaptchaToken?: string;
};

export type SignIn = (request: IAuthRequest) => Promise<IAuthResponse>;
export type GetRefreshToken = (refreshToken: string) => Promise<string>;

export const signIn: SignIn = selectedModule.signIn;
export const changePassAndSignIn: SignIn = selectedModule.changePassAndSignIn;
export const getRefreshToken: GetRefreshToken = selectedModule.getRefreshToken;
