import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import { usePermissionsContext } from '../../../../stores';
import { AlertOutlinedIcon } from '../../../../Icons/AlertOutlined';
import { CheckCircleIcon } from '../../../../Icons/CheckCircle';
import { useEmergencyNotifications } from '../../../emergency/hooks/useEmergencyNotifications';
import { CardHeader } from '../CardHeader';
import { DangerIcon } from '../../../../Icons/Danger';
import { UserGroupRoute } from '../../../../types';

import * as S from './styles';

interface Props {}

export const EmergencyCard: React.FC<Props> = ({}) => {
  const history = useHistory();
  const { permissions } = usePermissionsContext();
  const { notifications } = useEmergencyNotifications();

  const handleLinkClick = (e: any) => {
    e.preventDefault();

    const { info } = Modal;

    if (permissions?.routes.find((route:UserGroupRoute) => route.key === "emergencia")) {
      history.push('/emergencia');
    } else {
      info({
        title: 'Você não possui acesso a página de emergências neste momento.',
        bodyStyle: {
          font: 'normal 500 2rem/1.5 Work Sans, sans-serif',
          display: 'flex',
          justifyContent: 'center',
        },
        className: 'modal-logout',
        onOk: () => {},
      });
    }
  }
  
  return (
    <div>
      <CardHeader icon={<AlertOutlinedIcon />} title="Emergências" subtitle="(Tempo real)" />
      {!notifications.data.length ? (
        <>
          <CheckCircleIcon />
          <S.MainMessage type="success">Nenhuma emergência ocorrendo agora!</S.MainMessage>
        </>
      ) : (
        <>
          <DangerIcon width={59} height={56} fill="#ED3A3A" />
          <S.MainMessage type="error">
            {notifications.data.length >= 2
              ? 'Algumas emergências estão ocorrendo agora!'
              : 'Uma emergência está ocorrendo agora!'}
            <br />
            Vá para a{' '}
            <b>
              {/*<Link to="/emergencia">página de emergências</Link-->*/}
              <a href="/emergencia" onClick={handleLinkClick}>página de emergências</a>
            </b>{' '}
            para verificar o ocorrido
          </S.MainMessage>
        </>
      )}
    </div>
  );
};
