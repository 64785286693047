import React, { useState } from 'react';

import { VerticalBarChart } from './components/VerticalBarChart';
import { IconLoading } from '../../../../components/IconLoading';
import { CardAvgcontent, GroupingButton, OptionsCard } from './styles';
import { useInOutContext } from './context/InOutContext';
import { InOutChartHeader } from './components/InOutChartHeader';
import { useInOut } from './hooks/UseInOut';
import { useAuth } from '../../../../hooks/useAuth';
import { useAuthContext } from '../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../services/SystemMonitor';

const InOutChart: React.FC = () => {
  const { inOutChartData, inOutValues, areaSelected, setAreaSelected, setTimeVisualization } = useInOutContext();
  const { isLoading, exportDataToXlsx } = useInOut();
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleOnClick = (week: boolean, month: boolean, year: boolean, track: string, eventName: string) => {
    setTimeVisualization({ week, month, year });
    mixpanelTrack(monitorAgent, track, userName, authData, eventName);
  };

  return (
    <>
      <InOutChartHeader areaSelected={areaSelected} setAreaSelected={setAreaSelected} data={inOutValues} exportDataToXlsx={exportDataToXlsx} />
      <CardAvgcontent>
        {isLoading && <IconLoading />}
        {!isLoading && <VerticalBarChart inOutChartData={inOutChartData} />}
      </CardAvgcontent>
      <OptionsCard>
        <GroupingButton
          onClick={() => handleOnClick(false, false, false, 'Conformidade - Chegada/Saída - Diário', 'Indic>prodChegadasEsaidas>Diário')}>
          Diário
        </GroupingButton>
        <GroupingButton
          onClick={() => handleOnClick(true, false, false, 'Conformidade - Chegada/Saída - Semanas', 'Indic>prodChegadasEsaidas>Semanas')}>
          Semanas
        </GroupingButton>
        <GroupingButton
          onClick={() => handleOnClick(false, true, false, 'Conformidade - Chegada/Saída - Meses', 'Indic>prodChegadasEsaidas>Meses')}>
          Meses
        </GroupingButton>
        <GroupingButton
          onClick={() => handleOnClick(false, false, true, 'Conformidade - Chegada/Saída - Anos', 'Indic>prodChegadasEsaidas>Anos')}>
          Anos
        </GroupingButton>
      </OptionsCard>
    </>
  );
};

export default InOutChart;
