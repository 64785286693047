import { MonitoredAreasInEmergencyData } from "../../../types/MonitoredData";

export type EvacuationAreaMode = 'standby' | 'evacuating' | 'notevacuating';

export interface EvacuationSnapshot {
  currentCollaboratorsInArea: MonitoredAreasInEmergencyData[];
  currentSnapshot: AreasInEvacuationSnapshot[];
  collaboratorsInDangerAtStart: string[];
  collaboratorsInDangerAreas: string[];
  dangerAreas: string[];
  safeArea: string;
  mode: EvacuationAreaMode;
  initialTimestamp: number;
  elapsedTime: number;
  loadingData: boolean;
  collaboratorsSeenInSafeAreas: string[];
}

export interface AreasInEvacuationSnapshot {
  area: string;
  total: number;
  collaborators: string[];
  issafearea: boolean;
}

export interface EvacuationAreaTitleProps {
  title: string;
  icon: React.ReactElement;
}

export interface EvacuationAreaPorfessionsDataProps {
  emptyScenarioMessage: string;
  monitoredAreas?: AreasInEvacuationSnapshot;
}

export const evacuationButtonTexts: Record<EvacuationAreaMode, string> = {
  standby: 'Iniciar modo de evacuação',
  evacuating: 'Finalizar modo de evacuação',
  notevacuating: 'Modo de evacuação',
}

export interface NotificationItem {
  event: string;
  timestamp: string;
  area: string;
  tag: string;
  alias: string;
}