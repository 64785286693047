import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Buttons/Button';
import * as XLSX from 'xlsx'

import { useConformityContext } from '../context/ConformityContext';
import { IconExport } from '../../../Icons/Export';
import { useAuthContext } from '../../../stores';
import { useAuth } from '../../../hooks';
import { mixpanelTrack } from '../../../services/SystemMonitor';

const ButtonContainer = styled.div`
  display: flex
`

const ExportButton = styled(Button)`
  border: solid 2px #2F4CDD;
  background: #ffffff;
  color: #2F4CDD;
  height: 45px;
  gap: 14px;
  border-radius: 8px;
`

export const ExportButtonContainer: React.FC = () => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const {planningData} = useConformityContext();
  
  const exportData = () => {
    let payValue: number[] = [];
    let prodValue: number[] = [];
    let generalValue: number[] = [];
    let date: string[] = [];
    let type = 1;
    planningData.map(i => {
      if(i.type === 'HH Pago'){
        payValue.push(i.value);
        date.push(i.time);
      }

      else if(i.type === 'Programado'){
        payValue.push(i.value);
        date.push(i.time);
        type = 2;
      }

      else if (i.type === 'HH Produtivo'){
        prodValue.push(i.value);
      }

      else if (i.type === 'trackfy'){
        prodValue.push(i.value);
      }

      else if(i.type === 'HH Geral'){
        generalValue.push(i.value);
      }
    })

    const formattedData: any[] = [];
    
    if (type == 1) {
      formattedData.push(["Data", "HH Pago", "HH Produtivo", "HH Geral"]);

      date.forEach((date, index) => {
        const value1 = payValue[index];
        const value2 = prodValue[index];
        const value3 = generalValue[index];
        formattedData.push([date, value1, value2, value3]);
      });

      return formattedData;
    } 
    
    formattedData.push(["Data", "Programado", "Trackfy"]) ;
    
    date.forEach((date, index) => {
      const value1 = payValue[index];
      const value2 = prodValue[index];
      formattedData.push([date, value1, value2]);
    });

    return formattedData
  }

  const handleExport = () => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(exportData());
    XLSX.utils.book_append_sheet(wb, ws, "Comparativo HH");
    XLSX.writeFile(wb, "Comparativo HH.xlsx");
	mixpanelTrack(monitorAgent, 'Indicadores - Conformidade - PlanejadoVSRealizado - Exportar', userName, authData, 'Indic>abaConfPlanVSReal>Export');
  }

  return(
      <ButtonContainer>
        <ExportButton
          title='Exportar'
          onClick={() => handleExport()}
          icon={<IconExport/>}
        />
      </ButtonContainer>
  )
};