import React, { useEffect } from 'react';

import { HomeHeader } from './components/Header';
import { MonitoringCard } from './components/MonitoringCard';
import { EmergencyCard } from './components/EmergencyCard';
import { HelpCard } from './components/HelpCard';
import { InOutCard } from './components/InOutCard';
import { PTWithdrawalCard } from './components/PTWithdrawalCard';
import { PagesCard } from './components/PagesCard';
import { InOutContextProvider } from '../productivity/charts/InOut/context/InOutContext';
import { useAuthContext } from '../../stores/AuthContext';
import { useAuth } from '../../hooks/useAuth';
import { mixpanelTrack } from '../../services/SystemMonitor';

import * as S from './styles';
import { Container } from '../../components/PageStructure/styles';
import { Content } from './styles';

interface Props {}
export const HomeContainer: React.FC<Props> = ({}) => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  
  useEffect(() => mixpanelTrack(monitorAgent, 'Tela Home', userName, authData, 'Tela Home'),[]);
  
  return (
    <Container>
      <HomeHeader periodType="Mensal" stage="acompanhamento" />
      <Content>
        <MonitoringCard />
        <S.ContainerTwoCards>
          <EmergencyCard />
          <HelpCard />
        </S.ContainerTwoCards>
        <InOutContextProvider>
          <InOutCard />
        </InOutContextProvider>
        <PTWithdrawalCard />
        <PagesCard />
      </Content>
    </Container>
  );
};
