import React from 'react';

import IconUsers from '../../../../../Icons/Users';
import { useAuth } from '../../../../../hooks';
import { IconContainer } from '../../../styles';
import { Info } from '../../../../../components/Info';
import { getWorkingPermitTooltipContent } from './WorkingPermitIntoTool';

import * as S from '../styles';

export const WorkingPermitChartHeader: React.FC = () => {
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getWorkingPermitTooltipContent(clientId);

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Estimativa de Retirada de PT</S.Title>
        <IconContainer>
		   <Info 
            content={tooltipContent} 
            position={'left'} 
            burndown={false}
            screen={'Prod'}
            eventName={'RetDePT'}/>
        </IconContainer>
      </S.Titlecontainer>
    </S.Header>
  );
};
