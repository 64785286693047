import React from "react";
import { QuestionsAndAnswers } from "../types/Faq";

/*export const AbouSystemQuestionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Qual o raio de alcance do scanner de vocês?',
        answer: 'O raio máximo estimado é de 40 metros para ambientes abertos e com pouquíssima interferência. ' +
            'Porém, independentemente do tipo de ambiente, não informaremos algo como: “a TAG X está a X metros de distância do ' +
            'scanner”, o que será informado será sob influência de qual scanner está cada TAG, ou seja, para cada área que se ' +
            'tenha interesse em monitorar, será necessário instalar um scanner para diferenciar a captura de dados das outras ' +
            'áreas.'
    },
    {
        question: 'Qual a precisão do scanner?',
        answer: 'A precisão do scanner é de cerca de 5 a 10 metros, porém não utilizamos algoritmos como o de ângulo ' +
            'de chegada para definir a posição de uma TAG em relação ao scanner, ou seja, o que monitoramos é a presença das ' +
            'TAGs sob influência de cada scanner.'
    },
    {
        question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
        answer: '127 ou 220 V.'
    }
];

export const CommonQuestionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Qual o raio de alcance do scanner de vocês?',
        answer: 'O raio máximo estimado é de 40 metros para ambientes abertos e com pouquíssima interferência. ' +
            'Porém, independentemente do tipo de ambiente, não informaremos algo como: “a TAG X está a X metros de distância do ' +
            'scanner”, o que será informado será sob influência de qual scanner está cada TAG, ou seja, para cada área que se ' +
            'tenha interesse em monitorar, será necessário instalar um scanner para diferenciar a captura de dados das outras ' +
            'áreas.'
    },
    {
        question: 'Qual a precisão do scanner?',
        answer: 'A precisão do scanner é de cerca de 5 a 10 metros, porém não utilizamos algoritmos como o de ângulo ' +
            'de chegada para definir a posição de uma TAG em relação ao scanner, ou seja, o que monitoramos é a presença das ' +
            'TAGs sob influência de cada scanner.'
    },
    {
        question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
        answer: '127 ou 220 V.'
    }
];

export const FirstStepsQuestionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Qual o raio de alcance do scanner de vocês?',
        answer: 'O raio máximo estimado é de 40 metros para ambientes abertos e com pouquíssima interferência. ' +
            'Porém, independentemente do tipo de ambiente, não informaremos algo como: “a TAG X está a X metros de distância do ' +
            'scanner”, o que será informado será sob influência de qual scanner está cada TAG, ou seja, para cada área que se ' +
            'tenha interesse em monitorar, será necessário instalar um scanner para diferenciar a captura de dados das outras ' +
            'áreas.'
    },
    {
        question: 'Qual a precisão do scanner?',
        answer: 'A precisão do scanner é de cerca de 5 a 10 metros, porém não utilizamos algoritmos como o de ângulo ' +
            'de chegada para definir a posição de uma TAG em relação ao scanner, ou seja, o que monitoramos é a presença das ' +
            'TAGs sob influência de cada scanner.'
    },
    {
        question: 'Qual a voltagem de operação dos hardwares a serem instalados em campo?',
        answer: '127 ou 220 V.'
    }
];*/

export const DocumentationSubjectQuestionsAndAnswers: QuestionsAndAnswers[]  =  [
    {
        question: 'Como é a relação da Trackfy com a LGPD (Lei Geral de Proteção de Dados Pessoais)?',
        answer: 'O sistema Trackfy não armazena dados sensíveis dos trabalhadores com TAGs, todo o monitoramento ' +
            'realizado pela Trackfy já é executado de forma não automática por observações oculares em campo e para maiores ' +
            'dúvidas, o seguinte parecer legal pode ser consultado: ',
            link: <a href="https://drive.google.com/file/d/1r5VoR2rXrsLhI7xmjxW4VCLqFs5H1Oq1/view?usp=sharing" target="_blank">Parecer Trackfy - Proteção de Dados e Trabalhista</a>,
			number: 1
    },
    {
        question: 'Como fazer orçamento para um novo modelo?',
        answer: 'Para elaborar um novo orçamento, necessitamos entender o novo escopo e para isso precisamos de um ' +
            'descritivo das áreas e do quantitativo de recursos que serão monitorados, além do período considerado para ' +
            'o contrato. Assim, poderemos realizar um estudo de cobertura para estimar os quantitativos a seguir e precificar ' +
            'a solução: tags, scanners e gateways.',
		number: 2
    },
    {
        question: 'A Trackfy cobra por licença (Nº de Usuários)?',
        answer: 'Não, a Trackfy não cobra por licença! Cada cliente pode ter quantos usuários do sistema Trackfy ' +
            'durante o período de contrato.',
		number: 3
    }
]

export const GeneralSubjectQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'Como são divididas as áreas entre produtivas e improdutivas?',
        answer: 'É definido com cada cliente quais áreas são consideradas produtivas e quais são improdutivas, de ' + 
            'forma prévia ao Kickoff do projeto, com base nos locais onde serão realizadas cada tipo de atividades.',
		number: 5
    },
    {
        question: 'Como saber quem são as pessoas mostradas no sistema?',
        answer: 'A Trackfy não armazena dados sensíveis do monitoramento de trabalhadores, como nome de cada um deles. ' +
            'O que pode ser identificado é apenas qual TAG se refere a cada uma daquelas movimentações, a função, a empresa ' +
            'e o período no qual ela está associada aquele trabalhador.',
		number: 6
    },
    {
        question: 'Quanto tempo leva para o que acontece em campo ser mostrado na tela? (latência)?',
        answer: 'A latência entre o que acontece em campo e o que é apresentado no sistema Trackfy é de 10 segundos.',
		number: 7
    },
    {
        question: 'Como funciona o monitoramento em áreas não mapeadas?',
        answer: 'São chamadas de “Áreas não mapeadas”, todos os locais onde não foram instalados Scanners e Gateways ' +
            'da Trackfy. Então, assim que um trabalhador deixar as dependências do monitoramento, ele será marcado como ' +
            'em “Área não Mapeada”.',
		number: 8
    }
]

export const IndicatorsSubjectQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'Qual a diferença entre o gráfico ‘Planejado x Realizado’ e a ‘Linha do tempo de presença’?',
        answer: 'O Gráfico de Planejado x Realizado contabiliza a soma das horas registradas nas áreas marcadas ' +
            'para cada dia, enquanto a linha do tempo apenas registra a quantidade de trabalhadores visto a cada hora ' +
            'do dia nessa área, sem contabilizar o tempo de permanência deles.',
		number: 16
    }
]

export const LoginQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'Esqueci minha senha, e agora?',
        answer: ' Na tela de login você pode clicar em “Esqueci minha senha”, onde você vai digitar seu email e clicar em '
            + '“Redefinir senha”. Você receberá no seu email um link com acesso onde poderá criar uma nova senha!',
		number: 4
    }
]

export const MonitoringQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'De onde vem o HH monitorado?',
        answer: 'O HH monitorado é o acumulado de horas do time monitorado nas áreas selecionadas.',
		number: 13
    },
    {
        question: 'O que são colaboradores ativos?',
        answer: 'São colaboradores que estão sendo registrados em áreas consideradas como produtivas.',
		number: 14
    },
    {
        question: 'O mapa está desatualizado, o que fazer?',
        answer: ' O time de suporte da Trackfy deve ser contactado e um chamado deve ser aberto para a atualização.',
		number: 15
    }
]

export const OperationQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'Qual a base utilizada pra calcular os indicadores?',
        answer: 'A Trackfy tem uma série de algoritmos inteligentes para transformar os dados colhidos em campo em ' +
            'informações relevantes ao negócio monitorado.',
		number: 9
    },
    {
        question: 'O que acontece se a conectividade cair no local monitorado?',
        answer: 'Os Gateways da Trackfy possuem uma tecnologia para persistência de dados. Assim que a conectividade ' +
            'é interrompida os dados coletados para de ser transmitidos e passam a ser armazenados localmente, para quando ' +
            'a conexão for reestabelecida serem enviados para o sistema.',
		number: 10
    },
    {
        question: 'Qual tipo de alimentação utilizada para os hardwares e como mudá-la?',
        answer: 'Hoje, as alimentações disponíveis são 12v, 127v e 220v.',
		number: 11
    },
    {
        question: 'Os dispositivos da Trackfy são certificados para ambientes explosivos?',
        answer: 'Desde julho de 2024, a Trackfy tem dispositivos certificados para ambientes explosivos e podemos '
            + 'compor o sistema com hardwares ex. Caso tenha interesse, entre em contato com o nosso time comercial: ',
        link: <a href="https://trackfyapp.com.br/#contato" target="_blank">Trackfy - Analytics para indústrias e obras</a>,
		number: 12
    }
]

export const LinkCollaboratorsQuestionsAndAnswers: QuestionsAndAnswers[] = [
    {
        question: 'Como vincular um novo colaborador a uma TAG?',
        answer: 'Na pagina “Vinculação de colaborador” clique em “Novo colaborador” selecione a Tag, a empresa e a '
            + 'função do colaborador e clique em “Avançar”, confirme os dados na próxima tela e clique em “Vincular”! ',
		number: 17
    },
    {
        question: 'Como descubro o nome de um colaborador já vinculado?',
        answer: 'Estamos de acordo com a LGPD(Lei Geral de Proteção de Dados), então não podemos armazenar nomes dos '
            + 'funcionários em nosso banco de dados. Os colaboradores recebem um Alias (Sua função + Numero) para identificação no sistema.',
	    number: 18
    },
    {
        question: 'Como agrupar os colaboradores de uma empresa ou função especifica?',
        answer: 'Os filtros, logo acima da lista de funcionários, podem ser usados para mostrar apenas colaboradores '
            + 'de uma mesma empresa ou função.',
		number: 19
    }
]

export const EmergencyQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Como acionar o modo de evacuação?',
        answer: 'Na página “Emergências”, clique no botão “Modo de evacuação” no canto superior direito, selecione '
        + 'as áreas à serem evacuadas, pelo mapa ou pela lista de áreas, e clique em “Iniciar Modo de evacuação”.',
		number: 20
    },
    {
        question: 'Onde posso conseguir um histórico dos alertas?',
        answer: 'A página “Log de incidentes” registra todos os alertas que foram na planta.',
		number: 21
    }
]

export const IncidentsQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Como fazer para registrar que o incidente foi resolvido?',
        answer: 'Clique em “Resolver incidente” no canto direito da lista do incidente, digite um comentário a respeito '
            + 'de como foi a resolução e então clique em “Resolver”.',
		number: 22
    }
]

export const ReportsQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Como gerar um relatório?',
        answer: 'Na pagina “Relatórios”, selecione o tipo de relatório desejado e o período, também é possível filtrar '
         + 'esses relatórios por empresa, função, área ou recurso, e então clique em “Gerar prévia” para ver o relatório. Se desejar, é possível fazer o download desse relatório!',
		 number: 23
    },
    {
        question: 'Como fazer o download de um relatório gerado?',
        answer: 'Após gerar o relatório, o botão de “Download” aparecerá no canto superior direito da prévia!',
		number: 24
    },
    {
        question: 'Quais relatórios posso criar?',
        answer: 'Atualmente, tempos o “Relatório de HH”, “Relatório de Quantitativo de Trabalhadores” e '
            + '“Relatório de Chegadas e Saídas”.',
		number: 25
    }
]

export const UsersQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Como cadastrar um novo usuário?',
        answer: 'Na pagina “Usuários”, clique em “Convidar usuário”, digite o email, empresa e função do colaborador '
            + 'a ser convidado, clique em “Avançar”, confirme os dados e clique em “Convidar”!',
		number: 26
    },
    {
        question: 'Como excluir um usuário?',
        answer: 'Clique em “Detalhes” na lista do usuário que deseja excluir, clique em “Excluir usuário e confirme.',
		number: 27
    },
    {
        question: 'Posso passar minha conta para outra pessoa?',
        answer: 'Não é aconselhado o compartilhamento de contas, qualquer atividade feita nessa conta estará relacionada '
            + 'ao seu e-mail e será de sua responsabilidade! Aconselhamos enviar um convite para essa pessoa ou solicitar para '
            + 'seu gestor um convite para essa pessoa! ',
		number: 28
    },
    {
        question: 'Como descubro se o convite que enviei foi aceito?',
        answer: 'A barra do usuário na lista estará cinza enquanto o convite não for aceito e sua conta não tiver sido criada!',
		number: 29
    },
    {
        question: 'Convidei a pessoa errada, e agora?',
        answer: 'É possível cancelar o convite que ainda não foi aceito clicando em “Cancelar convite” na barra do usuário '
            + 'convidado na lista, caso ele tenha aceito você pode excluir o seu usuário!',
		number: 30
    },
    {
        question: 'O que é o histórico de acessos?',
        answer: 'É uma lista que registra todos os acessos daquele usuário no sistema, bem como o tempo que passou ativo!',
		number: 31
    }
]

/*export const HomeQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'O que significa o “Período dos dados”?',
        answer: 'É o período de base para os cálculos dos indicadores da pagina!'
    },
    {
        question: 'O que é a barra de progresso no topo da pagina?',
        answer: 'É uma barra de progresso referente ao momento do nosso projeto piloto que sua empresa se encontra!'
    },
    {
        question: 'Como faço pra ver a informação completa de um dos boxes?',
        answer: 'No canto superior de cada Box na home tem um ícone que redireciona para a pagina de onde aquele indicador foi inspirado!'
    }
]*/

export const DisplacementAtoBQuentionsAndAnswer: QuestionsAndAnswers[] = [
    {
        question: 'Como funciona o “Deslocamento entre áreas”?',
        answer: 'Na pagina “Deslocamento entre áreas” é preciso selecionar a área inicial e depois a área final nos '
            + 'dropdowns acima do mapa! Também é possível inverter a área inicial e final com o botão “Inverter” ao lado '
            + 'direito do Dropdown da área final!',
		number: 32
    }
]

