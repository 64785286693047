import { useEffect, useState } from 'react';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import useSWR from 'swr';
import { useAuth, useIsMounted } from '../../../hooks';
import { getEndpoint } from '../../../services/api/TrackfyService/endpoints';
import { getScannersStatus } from '../../../services/api/TrackfyService';
import { ScannersStatusData } from '../types';
import { useAuthContext } from '../../../stores';
import { mixpanelTrack } from '../../../services/SystemMonitor';

export const useScannersStatus = () => {
  const [status, setStatus] = useState<ScannersStatusData>({scanners: [], loading:true });
  const { getClientId } = useAuth();
  const mountedRef = useIsMounted();
  const currentRequest: CancelTokenSource = axios.CancelToken.source();
  const clientId = getClientId();
  const endpoint: string = getEndpoint('scannersStatus', false);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  const requestScannersStatus = async (cancelToken: CancelToken) => {
    const result = await getScannersStatus(clientId, cancelToken);
    
    setStatus({
      scanners: result,
      loading: false
    });
  };

  useSWR(
    mountedRef.current ? endpoint : null,
    async () => {
      requestScannersStatus(currentRequest.token);
    },
    {
      focusThrottleInterval: 10001,
      refreshInterval: 1000 * 60 * 10,
    }
  );

  useEffect(() => {
    if (mountedRef.current) {
      requestScannersStatus(currentRequest.token);
      mixpanelTrack(monitorAgent, 'Status dos scanners', userName, authData, 'paginaStatus')
    }

    return () => {
      mountedRef.current = false;
      currentRequest.cancel('request canceled by the user.');
    };
  }, []);

  return {
    status,
    clientId
  };
};