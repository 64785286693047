import React, { useEffect, useRef, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import { useResourcesMetricsContext } from '../context/ResourcesMetricsContext';
import { TimeDivisionData, TimeDivisionMetric } from '../types';
import { useReactToPrint } from 'react-to-print';
import { TooltipContent } from '../../../components/Info/Tooltip';
import { parseSelectOptions } from '../../../utils/Convert';
import { getResourcesTimeDivision } from '../../../services/api/TrackfyService/integration';
import { ReportIcon } from '../../../Icons/Report';
import { Info } from '../../../components/Info';
import { IconExport } from '../../../Icons/Export';
import { Modal } from '../../../components/Modal';

import * as S from '../styles';
import '../stylesheet.css';
import { IconLoading } from '../../../components/IconLoading';
import { ReportsHeader } from './ReportsHeader';
import { contervSecondsToTime } from '../../../utils/DateTime';

export const ResourcesTimeDivisionBars: React.FC = () => {
    const [dataMetrics, setDataMetrics] = useState<TimeDivisionData[]>([]);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { companySelected, professionSelected, initialDate, finalDate } = useResourcesMetricsContext();
    const ref = useRef<HTMLDivElement>(null);
    const cores = {
      produtivas: '#2f4cdd', //#4CAF50',  
      complementares: '#2196F3', 
      deslocamentos: '#FFC107',
      improdutivas: '#CCC',
  };

    useEffect(() => {
        let mounted = true;
        const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    
        if (mounted) {

          if(showPreview) {
            setLoading(true);
    
            getResourcesTimeDivision(
              parseSelectOptions(companySelected),
              parseSelectOptions(professionSelected),
              (new Date(initialDate.toString())).getTime(),
              (new Date(finalDate.toString())).getTime(),
              currentRequest.token
            ).then((response: AxiosResponse<TimeDivisionData[]>) => {
              setDataMetrics(response.data);
              setLoading(false);
            })
          } 
          else {
            setLoading(false);
            setDataMetrics([]);
          }

        }

        return () => {
          mounted = false;
          setDataMetrics([]);
          currentRequest.cancel('ResourcesTimeDivision request canceled by the user.');
        };
      }, [showPreview]);
    
    const toggleExpand = () => (setShowPreview(!showPreview));
    
    const getCurrentValue = (value?: number) => value ? parseInt(value.toString()) : 0;
    
    const getReportsTooltipContent = (): TooltipContent[] => 
         [
            {
              title: 'Produtivas - ',
              content: 'Tempo registrado em áreas produtivas.',
            },
            {
              title: 'Complementares - ',
              content: 'Tempo registrado áreas complementares como casas de PT.',
            },
            {
              title: 'Deslocamentos - ',
              content: 'Registros em áreas menores que 2 minutos, que indicam uma movimentação entre áreas.',
            },
            {
              title: 'Improdutivas - ',
              content: 'Registros em qualquer área em que não há captação da solução.',
            }
    ];
    
    const handlePrint = useReactToPrint({
        content: () => {
          const content = ref.current?.cloneNode(true);
          const printEl = document.createElement('div');;
          if (content) printEl.appendChild(content);
          return printEl;
        },
    });

    const calcularPercentual = (valor: number, total: number) => parseFloat( ((valor / total) * 100).toFixed(2) );
    
    return (
      <S.ResourceCard loading={loading} showPreview={showPreview}>
        <S.SubtitleHeader>
          <S.HeaderText>
            <ReportIcon />
            Recursos: Divisão de Tempo
          </S.HeaderText>
          <Info 
            content={getReportsTooltipContent()} 
            position={'right'} 
            burndown={false}
            screen={'Relat'}
            eventName={''}
          />
          <S.GenerateButton title='Exportar' onClick={() => toggleExpand()} icon={<IconExport/>} />
        </S.SubtitleHeader>

        {showPreview && (
          <Modal visible={showPreview}>
            <S.ModalTableContainer>
              <S.ModalHeaderContainer>
                {!loading && <S.ToggleButton onClick={handlePrint}> {'Export PDF'}</S.ToggleButton>}
                <S.ToggleButton onClick={toggleExpand}> {'X close'}</S.ToggleButton>
              </S.ModalHeaderContainer>
              <div>
                {loading &&
                    <S.LoadingContainer>
                        <IconLoading />
                    </S.LoadingContainer>
                }

                {!loading && (dataMetrics.length > 0) &&
                  <S.ReportContentContiner ref={ref}>
                    {dataMetrics.map((item, index) => {
                            const titles = Object.keys(item.empresas);

                            const header = 
                              <S.ReportHeaderLegendContiner key={item.data + "_header"} className="pagebreakbar">
                                <ReportsHeader title="Recursos: Divisão de Tempo" margin="0px !important" />
                                <S.LegendContainer>
                                  <S.LegendItem color="#2f4cdd">Produtivas</S.LegendItem>
                                  <S.LegendItem color="#2196F3">Complementares</S.LegendItem>
                                  <S.LegendItem color="#FFC107">Deslocamentos</S.LegendItem>
                                  <S.LegendItem color="#CCC">Improdutivas</S.LegendItem>
                                </S.LegendContainer>
                              </S.ReportHeaderLegendContiner>

                            const container = 
                                  <S.ReportDataContiner key={index} style={{ marginBottom: '20px' }}>
                                      <S.DataHeader>{item.data}</S.DataHeader>
                                      
                                      {Object.values(item.empresas).map((metrics, index) => {
                                        const companyTitle = titles[index];
                                        const bars: JSX.Element[] = [];                                        

                                        const section = (index > 0) ? <S.CompanyGapDiv/> : <></>;

                                        bars.push(<S.CompanyTitle>{companyTitle}</S.CompanyTitle>);

                                        metrics.forEach(empresa => {
                                          const prodFormatted = getCurrentValue(empresa.produtivas);
                                          const complFormatted = getCurrentValue(empresa.complementares)
                                          const deslocFormatted = getCurrentValue(empresa.deslocamentos);
                                          const improdFormatted = getCurrentValue(empresa.improdutivas);

                                          const total = prodFormatted + complFormatted + deslocFormatted + improdFormatted;

                                          if(total > 0)
                                            bars.push(
                                              <S.CompanyBarsContiner>
                                                <S.FunctionTitle key={index + "_" + empresa.funcao}>{empresa.funcao}</S.FunctionTitle>
                                                <S.ReportBarsContiner key={index + "_" + empresa.funcao + "+values"}>
                                                      {(prodFormatted > 0) &&
                                                        <S.Bars title={`Produtivas: ${calcularPercentual(prodFormatted, total)}%`}
                                                          //title={`Produtivas: ${contervSecondsToTime(prodFormatted)}`}
                                                          backgroundColor= {cores.produtivas}
                                                          width={`${calcularPercentual(prodFormatted, total)}%`}>
                                                            {`${calcularPercentual(prodFormatted, total)}%`} 
                                                            {/*(${contervSecondsToTime(prodFormatted)})`}*/}
                                                        </S.Bars>}
                                                      
                                                      {(complFormatted > 0) &&
                                                        <S.Bars title={`Complementares: ${calcularPercentual(complFormatted, total)}%`}
                                                          //title={`Complementares: ${contervSecondsToTime(complFormatted)}`}
                                                          backgroundColor= {cores.complementares}
                                                          width={`${calcularPercentual(complFormatted, total)}%`}>
                                                          {`${calcularPercentual(complFormatted, total)}%`}
                                                          {/*(${contervSecondsToTime(complFormatted)})`}*/}
                                                        </S.Bars>}
                                                      
                                                        {(deslocFormatted > 0) &&
                                                          <S.Bars title={`Deslocamentos: ${calcularPercentual(deslocFormatted, total)}%`} 
                                                            //title={`Deslocamentos: ${contervSecondsToTime(deslocFormatted)}`}
                                                            backgroundColor= {cores.deslocamentos}
                                                            width={`${calcularPercentual(deslocFormatted, total)}%`}>
                                                            {`${calcularPercentual(deslocFormatted, total)}%`} 
                                                            {/*(${contervSecondsToTime(deslocFormatted)})`}*/}
                                                          </S.Bars>}

                                                        {(improdFormatted > 0) &&
                                                          <S.Bars title={`Improdutivas: ${calcularPercentual(improdFormatted, total)}%`} 
                                                            //title={`Deslocamentos: ${contervSecondsToTime(deslocFormatted)}`}
                                                            backgroundColor= {cores.improdutivas}
                                                            width={`${calcularPercentual(improdFormatted, total)}%`}>
                                                            {`${calcularPercentual(improdFormatted, total)}%`} 
                                                            {/*(${contervSecondsToTime(deslocFormatted)})`}*/}
                                                          </S.Bars>}
                                                </S.ReportBarsContiner>
                                              </S.CompanyBarsContiner>
                                            )
                                        });
                                        
                                        return [section, bars];
                                      })}

                                  </S.ReportDataContiner>

                            const section = (index > 0) ? <S.SectionGapDiv/> : <></>;

                            return [ section, header, container ];
                              
                    })}
                  </S.ReportContentContiner>
                }
              </div>
            </S.ModalTableContainer>
          </Modal>
        )}
    </S.ResourceCard>
    );
}