import React from 'react';
import { Container } from '../../components/PageStructure/styles';
import { useScannersStatus } from './hooks/useScannersStatus';
import { PageHeader } from '../../components/PageStructure/PageHeader';
import { IconStatus } from '../../Icons/Status';
import { ScannertStatus } from './types';
import { IconLoading } from '../../components/IconLoading';
import { Info } from '../../components/Info';

import * as S from './styles';

export const ScannerStatusContainer: React.FC = () => {
  const { status, clientId } = useScannersStatus();
  const timeInterval = (clientId == 14) ? "30" : "60";
  const tooltipContent = [
    { title: 'Área - ', content: 'Local em que o dispositivo está instalado.' },
    { title: 'Gateway/Scanner - ', content: 'Descrição do dispositivo.' },
    { title: 'Status - ', content: 'Status atual do dispositivo, com base em sua alimentação e conectividade.' },
    { title: 'Última leitura - ', content: 'Horário da ultima leitura de presença de um colaborador.' },
    { title: 'Último status-check - ', content: 'Horário em que o dispositivo se registrou, no servidor, pela última vez. '
      + 'Essa rotina de registro é executada a cada ' + timeInterval + ' minutos.' }
  ];

  return (
    <Container>
      <PageHeader title="Status de hardware" />
      <S.Container>
        <S.ContentContainer>
          <S.ContentContainerHeader>
            <IconStatus />
            <h1>Detalhes dos status dos scanners</h1>
            <Info content={tooltipContent} position='right' burndown={true} screen={'StatusScanner'} eventName={''}/>
          </S.ContentContainerHeader>
          {status.loading ? (
            <IconLoading customDivStyle={{ marginTop: '20px', marginLeft: '45%', marginRight: '45%' }} />
          ) : (
            <S.Table>
              <S.Thead>
                <tr>
                  <td>Área</td>
                  <td>Gateway/Scanner</td>
                  <td>Status</td>
                  <td>Última leitura</td>
                  <td>Último status-check</td>
                </tr>
              </S.Thead>
              <S.Tbody>
                {status.scanners.map((item: ScannertStatus) => (
                  <tr key={item.scanner}>
                    <td>{item.local}</td>
                    <td>{item.scanner}</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <S.StatusCircle status={item.status} />
                        {item.status ? 'Online' : 'Offline'}
                      </div>
                    </td>
                    <td>{item.lastReading}</td>
                    <td>{item.lastCheck}</td>
                  </tr>
                ))}
              </S.Tbody>
            </S.Table>
          )}
        </S.ContentContainer>
      </S.Container>
    </Container>
  );
};
