import React, { useRef, useState } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { useAuth } from '../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';
import { RankByProfessionData } from '../../../../indicators/types/Indicators';
import { ProgressBar } from '../../../../../components/ProgressBar';

import * as S from '../styles';

type VerticalBarChartProps = {
  rankChartData: RankByProfessionData[];
  getColor: (value: number) => string;
};

export const ProgressBarChart: React.FC<VerticalBarChartProps> = (props: VerticalBarChartProps) => {
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);
  const { rankChartData, getColor } = props;
  const [firstScroll, setFirstScroll] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleScroll = () => {
    if (firstScroll) {
      mixpanelTrack(monitorAgent, 'Produtividade - Ranking', userName, authData, 'Indic>ProdRankProd');
      setFirstScroll(false);
    }
  };

  return (
    <ScrollBar ref={ScrollbarsRef} onScroll={handleScroll}>
      <S.ProgressBarChartContainer>
        {rankChartData
          .map((person: RankByProfessionData) => (
            <S.BarContainer key={person.name}>
              <ProgressBar
                label={person.name}
                value={person.value}
                color={getColor(person.value)}
              />
            </S.BarContainer>
          ))
          .sort((a: JSX.Element, b: JSX.Element) => {
            const newValue: number = a.props.children.props.value;
            const oldValue: number = b.props.children.props.value;

            if (newValue < oldValue) return 1;
            if (newValue > oldValue) return -1;
            return 0;
          })}
      </S.ProgressBarChartContainer>
    </ScrollBar>
  );
};
