import { useCallback, useEffect, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { useAuth } from '../../../../../hooks/useAuth';
import { useAuthContext } from '../../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';
import {
  addNewCollaborator,
  getNewCollaboratorAlias,
} from '../../../../../services/api/TrackfyService';
import { AddCollaborators } from '../../../types/linkCollaborators';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { notification } from '../../../../../utils/Notification';

export const useAddCollaborator = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addingCollaborator, setAddingCollaborator] = useState<boolean>(false);
  const [newAlias, setNewAlias] = useState<string>('');
  const [addCollaborator, setAddCollaborator] = useState<AddCollaborators>({
    profession: 0,
    company: 0,
    tag: 0,
    currentDate: moment(new Date()).toISOString(),
  });
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const setCompanySelected = useCallback((value: number) => {
    setAddCollaborator((prevState) => ({
      ...prevState,
      company: value,
    }));
  }, []);

  const setProfessionSelected = useCallback((value: number) => {
    setAddCollaborator((prevState) => ({
      ...prevState,
      profession: value,
    }));
  }, []);

  const setTagSelected = useCallback((value: number) => {
    setAddCollaborator((prevState) => ({
      ...prevState,
      tag: value,
    }));
  }, []);

  const isMounted = useIsMounted();

  const isDefined = (): boolean => {
    if (addCollaborator.company && addCollaborator.profession && addCollaborator.tag) return true;

    return false;
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;

    if (mounted && addCollaborator.profession > 0) {
      setIsLoading(true);

      getNewCollaboratorAlias(addCollaborator.profession, currentRequest.token)
        .then((data) => {
          setNewAlias(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }

    return () => {
      mounted = false;
      currentRequest.cancel(' request canceled by the user.');
    };
  }, [addCollaborator.profession]);

  const handleSaveCollaborator = async (): Promise<boolean> => {
    const isValidFields = isDefined();

    if (!isValidFields) return false;

    setAddingCollaborator(true);

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    const addNewCollaboratorRequest: AddCollaborators = {
      company: addCollaborator.company,
      profession: addCollaborator.profession,
      tag: addCollaborator.tag,
      currentDate: moment(new Date()).toISOString(),
    };

    await addNewCollaborator(addNewCollaboratorRequest, currentRequest.token)
      .then((response) => {
        notification('success', newAlias + ' vinculado com sucesso!');
        mixpanelTrack(monitorAgent, 'Vinculacao - Novo Colaborador Vinculado', userName, authData, 'Vinc>NovaVinc');
      })
      .catch((err) => {
        console.log(err);
        notification('error', 'Ocorreu um erro ao vincular o colaborador');
      });

    if (isMounted.current) setAddingCollaborator(false);
    return true;
  };

  return {
    isLoading,
    isDefined,
    handleSaveCollaborator,
    addingCollaborator,
    addCollaborator,
    setAddCollaborator,
    setCompanySelected,
    setProfessionSelected,
    setTagSelected,
  };
};
