import React, { useEffect } from 'react';

import { useAuthContext } from '../../../stores/AuthContext';
import { useAuth } from '../../../hooks/useAuth';
import { mixpanelTrack } from '../../../services/SystemMonitor';

import * as S from '../../../styles/structure';
import ProfessionSelect from '../../filters/ProfessionSelect';
import { useIncidentsContext } from '../context/IcidentsContext';
import { OptionsContainer } from '../../../components/PageStructure/styles';
import { DateRangePicker } from '../../../components';
import { getFollowingFriday } from '../../../utils/DateTime';
import AreaSelect from '../../filters/AreaSelect';

const IncidentsOptions: React.FC = () => {
  const {
    areaSelected,
    setAreaSelected,
    professionSelected,
    setProfessionSelected,
    updatePeriod
  } = useIncidentsContext();
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Log de Incidentes', userName, authData, 'paginaLogDeIncid'), []);

  return (
    <OptionsContainer>
      <S.SimpleContainer>
        <ProfessionSelect
          label={undefined}
          professionSelected={professionSelected}
          setProfessionSelected={setProfessionSelected}
          screen={'Log de Incidentes'}
		  eventName={'incidents'}
        />
      </S.SimpleContainer>

      <S.SimpleContainer>
        <AreaSelect
          label={undefined}
          areaSelected={areaSelected}
          setAreaSelected={setAreaSelected}
          screen={'Log de Incidentes'}
		  eventName={'incidents'}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Log de Incidentes"
          maxDate={getFollowingFriday(new Date())}
          component="Log de Incidentes - "
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
    </OptionsContainer>
  );
};

export default IncidentsOptions;
