import React from 'react';

import { useTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';
import { XlsxExportButton } from '../../../components/Buttons/XlsxExportButton';

export const TimelineExportButton: React.FC = () => {
  const { timelineValues } = useTimelineContext();
  const { exportDataToXlsx } = useTimeline();

  return <XlsxExportButton data={timelineValues} 
                           exportData={exportDataToXlsx} 
                           marginLeft='20px' 
                           fileName='Timeline'
                           screen={'Indicadores - Conformidade - Timeline'}
                           eventName={'Indic>abaConfTimeline'}/>
};
