import React from 'react';
import * as XLSX from 'xlsx'

import { Button } from '../../../components/Buttons/Button';
import { IconExport } from '../../../Icons/Export';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuthContext } from '../../../stores';
import { useAuth } from '../../../hooks';

import styled from 'styled-components';

interface ContainerProps {
  marginLeft?: string;
  marginBottom?: string;
}

const ButtonContainer = styled.div<ContainerProps>`
  display: flex;
  max-width: 150px !important;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`

const ExportButton = styled(Button)`
  border: solid 2px #2F4CDD;
  background: #ffffff;
  color: #2F4CDD;
  height: 45px;
  gap: 14px;
  border-radius: 8px;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
interface Props {
  data: any[];
  exportData?: (data: any[]) => any[];
  marginLeft?: string;
  marginBottom?: string;
  fileName: string;
  disabled?: boolean;
  exportAsync?: (data: any[]) => Promise<any[]>;
	screen: string;
	eventName: string;
}

export const XlsxExportButton: React.FC<Props> = (props: Props) => {
  const { data, exportData, marginLeft, marginBottom, fileName, disabled, exportAsync, eventName, screen } = props;
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  
  const handleExport = async () => {
    let wb = XLSX.utils.book_new();
    let ws: XLSX.WorkSheet | undefined = undefined;

    if (exportData) 
      ws = XLSX.utils.aoa_to_sheet(exportData(data));

    if (exportAsync) {
      const dataToExport = await exportAsync(data);
      ws = XLSX.utils.aoa_to_sheet(dataToExport);
    }

    if (!ws) 
      return;

    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    mixpanelTrack(monitorAgent, `${screen} - Exportar`, userName, authData, `${eventName}>Export`);
  }

  return(
      <ButtonContainer marginLeft={marginLeft} marginBottom={marginBottom}>
        <ExportButton
          title='Exportar'
          onClick={() => handleExport()}
          icon={<IconExport/>}
        />
      </ButtonContainer>
  )
};