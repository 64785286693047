import moment from 'moment';
import React, { useState } from 'react';

export interface DateRangeProps {
  updatePeriod: (initial: any, final: any) => void;
  screen: string;
  component: string;
  maxDate: Date;
  selectRange: boolean;
  showToday: boolean;
  loadDate: Date;
  customIcon?: string;
  id?: string;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  limitDateRange?: number;
  placeholder?: string;
}

export const useDateRangePicker = (props: DateRangeProps) => {
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
  const [calendar, setCalendar] = useState(false);
  let calendarIsOpened = false;

  const { updatePeriod,limitDateRange } = props;

  const parseToDateFormatted = (initialDate: Date, finalDate: Date) => {
    let parsedDate: any = '';
    if (moment(initialDate).format('DD/MM/YYYY') === moment(finalDate).format('DD/MM/YYYY')) {
      parsedDate =
        moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
          ? 'Hoje'
          : `${moment(initialDate).locale('pt-br').format('DD/MM/YY')}`;
    } else if (moment(initialDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
      parsedDate = `Hoje ~ ${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
    } else if (moment(finalDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
      parsedDate = `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ Hoje`;
    } else {
      parsedDate =
        `${moment(initialDate).locale('pt-br').format('DD/MM/YY')} ~ ` +
        `${moment(finalDate).locale('pt-br').format('DD/MM/YY')}`;
    }
    return parsedDate;
  };

  const [date, setDate] = useState(parseToDateFormatted(new Date(), new Date()));
  const [currentInitialDateSelected, setCurrentInitialDateSelected] = useState<Date>();

  // eslint-disable-next-line no-shadow
  const onChange = (date: Date | Date[]) => {
    // calendarIsOpened = true;
    if (date instanceof Array) setSelectedDate(date);
    else setSelectedDate([date, date]);
  };

  const toggleCalendar = () => {
    setTimeout(() => {
      if (!calendarIsOpened) setCalendar(!calendar);
    }, 250);
  };

  const handleConfirmDate = () => {
    setDate(parseToDateFormatted(selectedDate[0], selectedDate[1]));
    updatePeriod(moment(selectedDate[0]), moment(selectedDate[1]));
    setCalendar(!calendar);
  };

  const handleClickToday = () => {
    setDate(parseToDateFormatted(new Date(), new Date()));
    updatePeriod(moment(new Date()), moment(new Date()));
    setCalendar(!calendar);
  };

  const updateCalendarState = (open: boolean) => {
    calendarIsOpened = open;
  };

  const getMaxDateLimited = () => {
    if (!currentInitialDateSelected) return new Date();
    if (limitDateRange === undefined) return;
    const newDate = new Date(
      currentInitialDateSelected.getFullYear(),
      currentInitialDateSelected.getMonth(),
      currentInitialDateSelected.getDate() + limitDateRange
    );
    return newDate > new Date() ? new Date() : newDate;
  };

  const getMinDateLimited = (limitDate: number) => {
    if (!currentInitialDateSelected) return;
    if (limitDate === undefined) return;
    return new Date(
      currentInitialDateSelected.getFullYear(),
      currentInitialDateSelected.getMonth(),
      currentInitialDateSelected.getDate() - limitDate
    );
  };

  return {
    date,
    calendar,
    onChange,
    setCalendar,
    toggleCalendar,
    handleConfirmDate,
    handleClickToday,
    updateCalendarState,
    getMaxDateLimited,
    getMinDateLimited,
    setCurrentInitialDateSelected,
  };
};
