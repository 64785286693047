import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../../../Icons/Users';
import { useAuth } from '../../../../../hooks';
import { IconContainer } from '../../../styles';
import { Info } from '../../../../../components/Info';
import { getPeopleInAreasTooltipContent } from './PeopleInAreasTooltip';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  /*& > svg {
    margin-right: 1.6rem;
  }*/
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const PeopleInAreasChartHeader: React.FC = () => {
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getPeopleInAreasTooltipContent(clientId);

  return(
    <Header>
      <Titlecontainer>
        <IconUsers />
        <Title>Recursos por áreas</Title>
        <IconContainer>
          <Info 
            content={tooltipContent} 
            position={'right'} 
            burndown={false}
            screen={'Prod'}
            eventName={'Rec/Area'}
          />
        </IconContainer>
      </Titlecontainer>
    </Header>
  )
};

export default PeopleInAreasChartHeader;
