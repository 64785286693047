import React from 'react';
import { MapProvider, Map, Marker } from 'react-map-gl';
import { IconLoading } from '../../../../../../components/IconLoading';
import { SimpleContainer } from '../../../../../../styles/structure';
import ClientSelect from '../../../../../filters/ClientSelect';
import { useRegisterAreaContext } from '../../context/RegisterAreaContext';
import DrawControl from './DrawPolygon';
import { useArea } from './hooks/useArea';

import { useMap } from './hooks/useMap';

import * as S from './styles';

type DrawMapProps = {
  isAdmin: boolean;
}

export const DrawMap: React.FC<DrawMapProps> = ({isAdmin}) => {
  const { latitudeRef, longitudeRef, initializeMap, mapStyle, zoomRef, mapRef, handleDrawCreate, onDelete } =
    useMap(isAdmin);
  const { clientSelected, setClientSelected } = useRegisterAreaContext();
  const { mapAreas, createArea, getMapBackground } = useArea(isAdmin);

  if (!initializeMap)
    return (
      <S.LoadingContainer>
        <IconLoading />
      </S.LoadingContainer>
    );

  return (
    <div>
      <MapProvider>
        <Map
          id="mymap"
          initialViewState={{
            longitude: longitudeRef.current,
            latitude: latitudeRef.current,
            zoom: zoomRef.current,
          }}
          style={{ height: '55vh' }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
          mapStyle={mapStyle}
          ref={mapRef}
        >
          {isAdmin && 
            <S.FilterContainer>
              <SimpleContainer>
                <ClientSelect clientSelected={clientSelected} setClientSelected={setClientSelected} omitCivil={true} />
              </SimpleContainer>
            </S.FilterContainer>}

          <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            defaultMode="draw_polygon"
            onCreate={handleDrawCreate}
            onUpdate={handleDrawCreate}
            onDelete={onDelete}
          />

          {getMapBackground()}
          {!mapAreas ? <React.Fragment /> : <>{createArea()}</>}
        </Map>
      </MapProvider>
    </div>
  );
};
