export const generalMovimentData = [
  {
    profession: 'Pintor',
    collaborator: 'pintor 2',
    area: 'ADMINISTRAÇÃO',
    company: 'Terceirizada',
    start: '2024-01-14T03:00:00.000Z',
    end: '2024-01-14T03:00:59.000Z',
  },
  {
    profession: 'Eletricista',
    collaborator: 'eletricista 3',
    area: 'GALPÃO DE PRODUÇÃO',
    company: 'Trackfy',
    start: '2024-01-14T03:00:00.000Z',
    end: '2024-01-14T03:00:59.000Z',
  },
  {
    profession: 'Eletricista',
    collaborator: 'eletricista 4',
    area: 'ADMINISTRAÇÃO',
    company: 'Terceirizada',
    start: '2024-01-14T03:00:00.000Z',
    end: '2024-01-14T03:10:59.000Z',
  },
];
