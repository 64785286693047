import React, { useEffect } from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { useConformityContext } from './context/ConformityContext';
import { DateRangePicker } from '../../components';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { getFollowingFriday } from '../../utils/DateTime';
import { useAuth } from '../../hooks';
import CompanyMultiSelect from '../filters/CompanyMultiSelect';
import AreaMultiSelect from '../filters/AreaMultiSelect';
import ProfessionMultiSelect from '../filters/ProfessionMultiSelect';

import { OptionsContainer } from '../../components/PageStructure/styles';
import * as S from '../../styles/structure';

const ConformityOptions: React.FC = () => {
  const { areaSelected,
          setAreaSelected,
          companySelected,
          setCompanySelected,
          professionSelected,
          setProfessionSelected,
          updatePeriod } = useConformityContext();
  const { authData } = useAuthContext();
  const compIds: string = authData ? authData.grpCompanies : 't';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  useEffect(() => mixpanelTrack(monitorAgent, 'Indicadores - Conformidade', userName, authData, 'Indic>abaConf'), []);

  return (
    <OptionsContainer>
      {compIds != 'a' && !compIds.includes(',') ? (<></>) : 
      (<S.SimpleContainer>
          <CompanyMultiSelect 
            itemsSelected={companySelected}
            setItemsSelected={setCompanySelected}
            screen={`Indicadores - Conformidade`}
			eventName={'indicConf'}
          />
        </S.SimpleContainer>
      )}
      <S.SimpleContainer>
        <ProfessionMultiSelect 
            itemsSelected={professionSelected}
            setItemsSelected={setProfessionSelected}
            screen={`Indicadores - Conformidade`}
			eventName={'indicConf'}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <AreaMultiSelect 
            itemsSelected={areaSelected}
            setItemsSelected={setAreaSelected}
            screen={`Indicadores - Conformidade`}
			eventName={'indicConf'}
        />
      </S.SimpleContainer>
      <S.SimpleContainer>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Indicadores"
          maxDate={getFollowingFriday(new Date())}
          component="Conformidade - "
          selectRange
          showToday
          loadDate={new Date()}
        />
      </S.SimpleContainer>
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};

export default ConformityOptions;
