import React from 'react';

import { FilterIconVuesax } from '../../../Icons/Filter-Vuesax';
import { AlertIconVuesax } from '../../../Icons/Alert-Vuesax';
import AreaSelect from '../../filters/AreaSelect';
import { useReportsContext } from '../context/ReportsContext';
import MonitoredResourcesSelect from '../../filters/MonitoredResourcesSelect';
import CompanySelect from '../../filters/CompanySelect';
import ProfessionSelect from '../../filters/ProfessionSelect';

import * as S from '../styles';

export const FilterSection: React.FC = () => {
  const {
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    collaboratorSelected,
    setCollaboratorSelected,
    areaSelected,
    setAreaSelected,
  } = useReportsContext();

  return (
    <S.FilterSection>
      <div>
        <span>
          <FilterIconVuesax />
          Filtros <p>(Opcional)</p>
        </span>
        <AlertIconVuesax />
      </div>
      <div>
        <S.InputBox>
          <S.InputLabel htmlFor="report-company">Empresa</S.InputLabel>
          <CompanySelect
            companySelected={companySelected}
            setCompanySelected={setCompanySelected}
            screen={`Relatórios`}
            eventName={'Relat'}
          />
        </S.InputBox>
        <S.InputBox>
          <S.InputLabel htmlFor="report-profession">Função</S.InputLabel>
          <ProfessionSelect
            professionSelected={professionSelected}
            setProfessionSelected={setProfessionSelected}
            screen={`Relatórios`}
            eventName={'Relat'}
          />
        </S.InputBox>
      </div>
      <div>
        <S.InputBox>
          <S.InputLabel htmlFor="report-area">Área</S.InputLabel>
          <AreaSelect
            areaSelected={areaSelected}
            setAreaSelected={setAreaSelected}
            screen={`Relatórios`}
            eventName={'Relat'}
          />
        </S.InputBox>
        <S.InputBox>
          <S.InputLabel htmlFor="report-collaborator">Recurso</S.InputLabel>
          <MonitoredResourcesSelect
            label={undefined}
            companySelected={companySelected}
            professionSelected={professionSelected}
            monitoredResourcesSelected={collaboratorSelected}
            setMonitoredResourcesSelected={setCollaboratorSelected}
            screen={`Relatórios`}
            eventName={'Relat'}
          />
        </S.InputBox>
      </div>
    </S.FilterSection>
  );
};
