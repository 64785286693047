import React from 'react';

import { useFaQContext } from '../context/FaqContext';
import { useSubject } from '../hooks/useSubject';
import { ItemsContainer } from './ItemContainer';
import { SubjectProps } from '../types/Faq';
import { IconFaQ } from '../../../Icons/Faq';

import * as S from './styled';

export const Subject: React.FC<SubjectProps> = (props: SubjectProps) => { 
    const {filters, title, questionsAndAnswers} = props;
    const {searchQuestion, subjectFilter} = useFaQContext();
    const {search, getFilters} = useSubject({searchQuestion: searchQuestion, subjectFilter: subjectFilter});
  
    const filter = search(questionsAndAnswers);

    if(getFilters(filters) || subjectFilter.length == 0)
        return (
            <React.Fragment>
                {filter.length > 0 &&
                    <S.SubjectContainer>
                        <S.SubjectTitle>
                            <IconFaQ/>
                            <S.SubjectTitleLabel>{title}</S.SubjectTitleLabel>
                        </S.SubjectTitle>
                        <S.ItemsContainer>
                            {filter.map((item: any) => 
                                <ItemsContainer key={item.question + item.answer} 
                                                question={item.question} 
                                                answer={item.answer}
                                                link={item.link}
												questionNumber={item.number}/>)}
                        </S.ItemsContainer>
                  </S.SubjectContainer>
                }
            </React.Fragment>
        )
    else 
      return null
};