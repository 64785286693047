import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { SelectOption } from '../../components/Select/types';
import { Select } from '../../components/Select';
import { getCompanies } from '../../services/api/TrackfyService';
import { Company } from '../../types/Filters';
import { getLinkedCollaboratorsCompanies } from '../../services/api/TrackfyService/integration';
import { mixpanelTrack } from '../../services/SystemMonitor';

type CompanySelectProps = {
  label?: string;
  companySelected: number;
  setCompanySelected: (value: number) => void;
  demo?: boolean;
  screen?: string;
  onChange?: (value: { id: number; name: string }) => void;
  eventName: string;
};

const CompanySelect: React.FC<CompanySelectProps> = (props: CompanySelectProps) => {
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const { companySelected, setCompanySelected, label, demo, screen, onChange, eventName } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      if (demo && demo == true) {
        getLinkedCollaboratorsCompanies(true, currentRequest.token)
          .then((result: AxiosResponse<any>) => {
            setCompanies(
              result.data.map((Company: Company) => {
                return {
                  label: Company.name,
                  value: Company.id ? Company.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        getCompanies(true, currentRequest.token)
          .then((result: AxiosResponse<any>) => {
            setCompanies(
              result.data.map((Company: Company) => {
                return {
                  label: Company.name,
                  value: Company.id ? Company.id.toString() : '0',
                };
              })
            );
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      }
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getCompanysApi request canceled by the user.');
    };
  }, []);

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCompanySelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;

    if (onChange)
      onChange({ id: Number(event.target.value), name: label });

    if (label != 'Selecione uma empresa')
      mixpanelTrack(monitorAgent, `${screen} - Empresa ${label} selecionada`, userName, authData, `${eventName}>filtEmpresa${label}`);
  };

  return (
    <Select
      id="avrgtime-Company-select"
      label={label}
      placeholder="Selecione uma empresa"
      options={companies}
      value={companySelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default CompanySelect;
