import React from 'react';

import { useEmergencyNotifications } from '../../hooks/useEmergencyNotifications';
import IconAlert from '../../../../Icons/Alert';
import IconFall from '../../../../Icons/Fall';
import { NotificationItem } from '../../types/Emergency';

import * as S from './styles';

interface Props {
  notification: NotificationItem;
  index: number;
}

export const NotificationRow: React.FC<Props> = ({ notification, index }) => {
  const { notificationsUpdates } = useEmergencyNotifications();
  const newNotification: boolean = index == 0 && notificationsUpdates.current < 3;

  return (
    <S.Notification new={newNotification} panic={notification.event == 'Botão de pânico disparado'}>
      <S.NotificationContentContainer>
        <S.NotificationIcon>
          {notification.event == 'Botão de pânico disparado' ? (
            <IconAlert fill={'#fff'} height="30" width="30" />
          ) : (
            <IconFall fill={'#fff'} />
          )}
        </S.NotificationIcon>
        <S.NotificationContent>
          <S.NotificationTitle new={newNotification}>
            {notification.timestamp} - {notification.event}
          </S.NotificationTitle>
          <S.NotificationDetails>
            <S.NotificationDetailsContent>
              <S.NotificationLabel new={newNotification}>área:</S.NotificationLabel>
              <S.NotificationInfo new={newNotification}>{notification.area}</S.NotificationInfo>
            </S.NotificationDetailsContent>
            <S.NotificationDetailsContent>
              <S.NotificationLabel new={newNotification}>colaborador:</S.NotificationLabel>
              <S.NotificationInfo new={newNotification}>
                {notification.alias} ({notification.tag})
              </S.NotificationInfo>
            </S.NotificationDetailsContent>
          </S.NotificationDetails>
        </S.NotificationContent>
      </S.NotificationContentContainer>
    </S.Notification>
  );
};