import React, { useCallback, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { Layer, Source } from 'react-map-gl';

import cookieService from '../../../../../../../services/CookieService';
import { useIsMounted } from '../../../../../../../hooks';
import { fetchData } from '../../../../../../../services/requests';
import { MapAreasData } from '../../../../../../../types/MonitoredData';
import { useRegisterAreaContext } from '../../../context/RegisterAreaContext';
import { getEndpoint } from '../../../../../../../services/api/TrackfyService/endpoints';

export const useArea = (isAdmin: boolean) => {
  const mountedRef = useIsMounted();
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const { clientSelected, updateAreas, getClientId } = useRegisterAreaContext();
  const token: any = cookieService.get('token');
  const user = localStorage.getItem('@trackfy_user_id');
  const userId = user ? Number.parseInt(user) : 1;

  const requestAreas = async (url: string): Promise<void> => {
    const currentClient = isAdmin ? clientSelected : getClientId();
    await fetchData(token, url, { clientId: currentClient })
      .then((res: AxiosResponse<any>) => {
        if (!res.data || res.data.length === 0) return;
        const areas = res.data.filter((item: any) => item.nome !== 'ÁREA NÃO MAPEADA');
        setMapAreas(areas);
      })
      // eslint-disable-next-line no-console
      .catch((error: any) => console.error(error));
  };

  const getMapBackground = useCallback(() => {
    const areasComponents: React.JSX.Element[] = [];
    
    if (getClientId() === 14) {
      if(userId == 69){
        areasComponents.push(
          <React.Fragment key="imgContiner">
            <Source
              id="image"
              type="image"
              key="image"
              url={"https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyoBackground.png"}
              coordinates={[
                [-47.12904659250427, -22.730839071213595],
                [-47.12711860811419, -22.731103028208906],
                [-47.127438748591885, -22.733459281123928],
                [-47.12939759678818, -22.733204803157022]
              ]}
            >
              <Layer type="raster" />
            </Source>
          </React.Fragment>
        );
      } else {
        areasComponents.push(
          <React.Fragment key="imgContiner">
            <Source
              id="image"
              type="image"
              key="image"
              url={"https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyoBackground.png"}
              coordinates={[
                [-47.129009039400984, -22.730909158284618],
                [-47.12714082178209, -22.731119024417026],
                [-47.127488118647705, -22.733460666794457],
                [-47.12934436051205, -22.73320662262644],
              ]}
            >
              <Layer type="raster" />
            </Source>
          </React.Fragment>
        );
      }
    
      areasComponents.push(
        <React.Fragment key="imgContiner2">
          <Source
            id="image2"
            type="image"
            key="image2"
            url="https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/toyo2Background.png"
            coordinates={[
              [-47.128323270810085, -22.725893644393494],
              [-47.12624187661439, -22.726215260368093],
              [-47.126697852147345, -22.72853582091129],
              [-47.12866659356379, -22.728273584699792],
            ]}
          >
            <Layer type="raster" />
          </Source>
        </React.Fragment>
      );
    } 
    
    if (getClientId() === 16) {
      areasComponents.push(
        <React.Fragment key="imgContiner2">
          <Source
            id="image2"
            type="image"
            key="image2"
            url="https://trackfy-frontend-assets.s3.us-east-2.amazonaws.com/pelirbackground.png"
            coordinates={[
              [-38.3610737560567, -12.920640493697775],
              [-38.35858503800438, -12.920041201039652],
              [-38.35839179636773, -12.920911601809905],
              [-38.360936144587725, -12.921393888142504]
            ]}
          >
            <Layer type="raster" />
          </Source>
        </React.Fragment>
      );
    }

    return areasComponents;
  }, []);

  useEffect(() => {
    const currentSimpleAreasRequest: CancelTokenSource = Axios.CancelToken.source(); 
    requestAreas(getEndpoint('simpleareas', false));

    return () => {
      mountedRef.current = false;
      currentSimpleAreasRequest.cancel('request canceled by the user.');
    };
  }, [clientSelected, updateAreas]);

  const parseDataToCoordinates = (coordinates: string) => {
    try {
      const coordinatesFormated = coordinates
        .replace('"', '')
        .split('],[')
        .map((coord: any) =>
          coord
            .replaceAll('[', '')
            .replaceAll(']', '')
            .split(',')
            .map((value: string) => Number(value))
        );

      return coordinatesFormated;
    } catch (_error) {
      return [];
    }
  };

  function createArea() {
    const areas = mapAreas
      .filter((area: MapAreasData) => area.coordenadas)
      .map((area: MapAreasData, idx: number) => {
        const { nome, cor, coordenadas } = area;

        const coordinates = parseDataToCoordinates(coordenadas);

        const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                title: nome,
              },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          ],
        };

        const paint: mapboxgl.FillPaint = {
          'fill-opacity': 0.4,
          'fill-color': cor,
        };

        const paintBorder: mapboxgl.LinePaint = {
          'line-width': 1,
          'line-color': cor,
        };

        return (
          <Source type="geojson" data={geojson} key={idx}>
            <Layer id={idx + nome} type="fill" paint={paint} />
            <Layer id={idx + nome + 'border'} type="line" paint={paintBorder} />
          </Source>
        );
      });

    return areas;
  }

  return { mapAreas, createArea, getMapBackground };
};
