import React from 'react';

import { useCompaniesContext } from '../../../context/CompaniesContext';
import CompanyMultiSelect from '../../../../filters/CompanyMultiSelect';

import * as S from '../../../styles';

export const CompanyFilter: React.FC = () => {
  const { companySelected, setCompanySelected } = useCompaniesContext();

  return (
    <S.CompanyFilterContainer>
      <CompanyMultiSelect itemsSelected={companySelected}
                          setItemsSelected={setCompanySelected}
                          screen={`RealTime - Conformidade`}
						  eventName={'TempoReal'}/>
    </S.CompanyFilterContainer>
  );
};