import React from 'react';

import { FilterCancelIcon } from '../../../Icons/FilterCancel';

import styled from 'styled-components';

interface Props {
  onClick?: () => void;
}

const Container = styled.button`
  all: unset;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ed3a3a;
  border-radius: 1.6rem;
  cursor: pointer;
  background-color: transparent;
`;

export const FilterCancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <FilterCancelIcon />
    </Container>
  );
};