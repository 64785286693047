import React, { ReactElement, useState } from 'react';
import { Layer, Marker, Source } from 'react-map-gl';

import { useAuthContext } from '../../../../stores';
import { useAuth } from '../../../../hooks';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { DisplacementBtwnAreasPopup } from './DisplacementBtwnAreasPopup';

import * as S from '../styles';
import { parseDataToCoordinates } from '../../../../utils/Convert';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

export interface DisplacementBtwnAreasMarkerProps {
    lat:number;
    lng:number;
    icon: ReactElement;
    contentName: string;
    color: string;
    coordinates: string;
};

export const  DisplacementBtwnAreasMarker: React.FC<DisplacementBtwnAreasMarkerProps> = (
    props: DisplacementBtwnAreasMarkerProps) => {
    const { lat,lng, icon, contentName, color } = props;
    const [showPopup, setShowPopup] = useState(false);
    const { authData, monitorAgent } = useAuthContext();
    const { USER_NAME } = useAuth();
    const userName = localStorage.getItem(USER_NAME);
  
    const onHover = () => {
      setShowPopup(true);
      mixpanelTrack(monitorAgent, 'Deslocamento entre áreas - Popup dos ícones',userName, authData, 'indic>moviDeslocPopup');
    };
  
    const onLeave = () => setShowPopup(false);

    const createGeofence = (area: DisplacementBtwnAreasMarkerProps): React.JSX.Element => {
      const coordinates = parseDataToCoordinates(area.coordinates);
    
      const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              title: area.contentName,
            },
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      };
            
      const paint: mapboxgl.FillPaint = {
        'fill-opacity': 0.4, 
        'fill-color': area.color,
      }
        
      let paintBorder: mapboxgl.LinePaint = {
        'line-width': 1, 
        'line-color': area.color,
      }
          
      return (
          <Source id={'id_src_' + area.contentName} type="geojson" data={geojson} key={'src_' + area.contentName}>
            <Layer id={'id_fill_' + area.contentName} type="fill" paint= {paint} key={'fill_' + area.contentName} />
            <Layer id={'id_border_' + area.contentName} type="line" paint= {paintBorder} key={'border_' + area.contentName}/>
          </Source>
      );
    }
  
    return (
      <>
        {createGeofence(props)}
        <Marker latitude={lat} longitude={lng}>
          <S.IconMarkerContainer color={color} onMouseEnter={onHover} onMouseLeave={onLeave}>
            {icon}
          </S.IconMarkerContainer>
        </Marker>
        {showPopup && (
          <DisplacementBtwnAreasPopup
            lng={lng}
            lat={lat}
            contentName={contentName}
          />
        )}
      </>
    );
  };