import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAuth } from '../../../hooks/useAuth';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuthContext } from '../../../stores/AuthContext';
import { TTimelineLine } from '../../indicators/types/Indicators';
import { Tooltip } from './components/Tooltip';

const Container = styled.div`
  position: relative;
  width: ${({ width = 12 }: TTimelineLine) => `calc((90% - 13px) / ${width})` || '100px'};
  flex: 0 0 ${({ width = 12 }: TTimelineLine) => `calc((90% - 13px )/ ${width})` || '100px'};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  cursor: pointer;

  @media (max-width: 1000px) {
    width: 100px;
    flex: 100px;
  }

  & span {
    font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
    color: #4f5882bf;
  }
`;

const BlinkPoint = styled.span`
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 100%;
  margin-left: 3px;
  background: ${({ background }: TTimelineLine) => background || '#C4C4C4'};
  animation: blinker 1s linear infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const Point = styled.span`
  display: block;
  width: 10px;
  height: 7px;
  border-radius: 100%;
  margin-left: 3px;
  background: ${({ background }: TTimelineLine) => background || '#C4C4C4'};
`;

const Line = styled.span`
  background: ${({ background }: TTimelineLine) => background || '#C4C4C4'};
  width: 100%;
  height: 3px;
  margin: 0 2px;
  display: block;
  margin-left: 4px;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-right: 49px;

  & span {
    font: normal 500 1.2rem/1.5 Work Sans, sans-serif;
    color: ${({ color }: TTimelineLine) => color || '#4f5882bf'};
  }

  @media (max-width: 1000px) {
    width: 100%;
    position: absolute;
    margin-right: -65px;
    margin-top: -85px;
  }

  @media (min-width: 1001px) and (max-width: 1100px) {
    margin-right: 18px;
  }

  @media (min-width: 1101px) and (max-width: 1400px) {
    margin-right: 28px;
  }

  @media (min-width: 1401px) and (max-width: 1600px) {
    margin-right: 38px;
  }
`;

const Data = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    transform: rotate(90deg);
  }
`;

const Time = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  margin-right: 20px;

  & span {
    margin-left: -10px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    position: absolute;
    margin-top: -85px;
  }

  @media (min-width: 1401px) and (max-width: 1600px) {
    margin-right: 28px;
  }
`;

export const TimelineLine: React.FC<TTimelineLine> = ({
  blink,
  background,
  color,
  count,
  time,
  width,
  data,
}: TTimelineLine) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const [showTooltipTrack, setShowTooltipTrack] = useState(false);

  if (showTooltip && !showTooltipTrack) {
    mixpanelTrack(monitorAgent, 'Conformidade - timeline', userName, authData, 'indic>confLinhaDoTempo');
    setShowTooltipTrack(true);
  }

  return (
    <Container
      width={width}
      onMouseMove={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      <Count color={color}>
        {count && <span>{parseInt(count) < 10 ? `0${count}` : count}</span>}
      </Count>
      <Data>
        {blink ? <BlinkPoint background={background} /> : <Point background={background} />}
        { <Line background={background} /> }
      </Data>
      <Time>{time && <span>{time}</span>}</Time>
      <Tooltip visible={showTooltip && !!data} data={data || []} />
    </Container>
  );
};
