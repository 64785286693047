import { useEffect, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import Map from 'react-map-gl';

import { useAuth, useIsMounted } from '../../../hooks';
import { GeolocationPosition } from '../../../types/Postion';
import { useAuthContext } from '../../../stores';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { CoordinatesResponse, getMapCoordinates } from '../../../services/api/TrackfyService';

export const useMap = (monitoredComponent : string, eventName: string) => {
  const [initializeMap, setInitializeMap] = useState<boolean>(false);

  const latitudeRef = useRef(0);
  const longitudeRef = useRef(0);
  const isManagerRef = useRef(false);
  const zoomRef = useRef(17);

  const isMounted = useIsMounted();
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v12';

  const mapGoToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      if (initializeMap) return;

      const latitude: number = Number(position.coords.latitude);
      const longitude: number = Number(position.coords.longitude);

      if (initializeMap) return;

      if (!isMounted.current) return;

      latitudeRef.current = latitude;
      longitudeRef.current = longitude;
      setInitializeMap(true);
    });
  };

  useEffect(() => {
    isMounted.current = true;

    if (isMounted.current) {
      const currentRequest: CancelTokenSource = Axios.CancelToken.source();

	  mixpanelTrack(monitorAgent, monitoredComponent, localStorage.getItem(USER_NAME), authData, eventName);

      let companies = '';
      if (authData) companies = authData.grpCompanies;
      isManagerRef.current = companies == 'a' || companies.includes(',');

      getMapCoordinates(currentRequest.token)
        .then((data: CoordinatesResponse) => {
          const { latitude, longitude, zoom } = data;

          if (!latitude || !longitude) return mapGoToCurrentLocation();
          if (initializeMap) return;

          latitudeRef.current = Number(latitude);
          longitudeRef.current = Number(longitude);
          zoomRef.current = zoom ? Number(zoom) : Number(17);
          
          if(isMounted.current)
            setInitializeMap(true);
        })
        .catch((_error) => mapGoToCurrentLocation());
    }

    return () => {
      isMounted.current = false;
    };
  }, []);
  
  return {
    initializeMap,
    mapStyle,
    Map,
    isManagerRef,
    latitudeRef,
    longitudeRef,
    zoomRef,
  };
};
