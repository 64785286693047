import React, { ReactElement } from 'react';
import { IconAddVuesax } from '../../../Icons/Add-Vuesax';
import { IconCloseVuesax } from '../../../Icons/Close-Vuesax';
import { useItem } from '../hooks/useItem';
import * as S from './styled'

type SubjectContainerProps = {
  question: string,
  answer: string,
  link?: ReactElement,
  questionNumber: number
};

export const ItemsContainer: React.FC<SubjectContainerProps> = (props: SubjectContainerProps) => {
  const {question, answer, link, questionNumber} = props
  const { itemExpanded, handleClickExpand } = useItem();

  return (      
    <S.ItemContent onClick={() => handleClickExpand(questionNumber)}  className={`${itemExpanded ? 'expanded' : 'collapsed'}`}> 
      <S.ItemIcon>
        {itemExpanded ? <IconCloseVuesax/> : <IconAddVuesax/>}
      </S.ItemIcon>

      <S.ItemQuestionAndAswer>
        <S.ItemQuestion>{question}</S.ItemQuestion>
        {itemExpanded && (
          <S.ItemAnswer>
            {answer} {link}
          </S.ItemAnswer>
        )}
      </S.ItemQuestionAndAswer>
    </S.ItemContent>
  );
};