import styled from 'styled-components';

import Calendar from '../../../assets/vector.svg';

export interface CalendarInputProps {
  customIcon?: string;
}

export const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CalendarInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
`;

export const CalendarInput = styled.input<CalendarInputProps>`
  width: 100%;
  height: 100%;
  padding-top: 13px;
  padding-right: 17px;
  padding-bottom: 13px;
  padding-left: 10px;
  cursor: pointer;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  //color: #4f5882;
  font-size: 14px;
  color: #000000;
  background: ${({ customIcon }) => `url(${customIcon || Calendar}) no-repeat right transparent`};
  background-position-x: calc(100% - 12px);
  //border: 1px solid #e4e8fb;
  border: 1px solid #6d6f79;
  border-radius: 8px;
  height: 46px;
  transition: border 0.3s ease-in-out;

  &:hover {
    border: thin solid #2f4cddbe;
  }

  &:focus {
    box-shadow: 0 0 0px 2.5px #2f4cdd44;
    border: thin solid #2f4cddbe;
    outline: 0;
  }
`;

export const CalendarGroup = styled.div`
  position: absolute;
  overflow: hidden;
  margin-top: 0.6rem;
  margin-bottom: 2rem;

  border: 1px solid #eee6e6;
  border-radius: 5px;
  box-shadow: 2px 2px 6px 4px rgb(80 94 165 / 13%);

  z-index: 1000;

  & > div.calendar {
    width: 100%;
    max-width: 350px;

    border: none;
  }
`;

export const CalendarOptsContainer = styled.div`
  width: 100%;
  height: 45px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
`;

export const CalendarConfirm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #86d655;
  border: 1px solid #86d655;
  border-radius: 8px;
  height: 33px;
  width: 100px;
  transition: border 0.3s ease-in-out;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #fff;
  padding: 1rem 1.6rem;

  cursor: pointer;
  &:hover {
    border: thin solid #86d655be;
  }
  &:focus {
    box-shadow: 0 0 0px 2.5px #86d65544;
    border: thin solid #86d655be;
  }
`;

export const CalendarCancel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #e45a5a;
  border: 1px solid #e45a5a;
  border-radius: 8px;
  height: 33px;
  width: 100px;
  transition: border 0.3s ease-in-out;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #fff;
  padding: 1rem 1.6rem;

  cursor: pointer;
  &:hover {
    border: thin solid #e45a5abe;
  }
  &:focus {
    box-shadow: 0 0 0px 2.5px #e45a5a44;
    border: thin solid #e45a5abe;
  }
`;

export const CalendarCurrent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e4e8fb;
  border-radius: 8px;
  height: 33px;
  width: 100px;
  transition: border 0.3s ease-in-out;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
  padding: 1rem 1.6rem;

  cursor: pointer;
  &:hover {
    border: thin solid #2f4cddbe;
  }
  &:focus {
    box-shadow: 0 0 0px 2.5px #2f4cdd44;
    border: thin solid #2f4cddbe;
  }
`;
