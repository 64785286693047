import styled from 'styled-components';
import { Button } from '../../../../components/Buttons/Button';
import { sg } from '../../../../styles/style-guide';

interface ResolveIncidentContainerProps {
  evacuation: boolean;
}

export const ResolveIncidentContainer = styled.div<ResolveIncidentContainerProps>`
  position: relative;
  width: 950px;
  height: ${({ evacuation }) => (evacuation ? '505px' : '419px')};
  //padding: 48px 51px 40px 34px;
  display: flex;
  flex-direction: column;
`;

export const ResolveIncidentHeaderTitleContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 24px 0px 0px 24px;
   justify-content: space-between;
  padding-right: 2.4rem;
`;

export const ResolveIncidentHeaderLabel = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  display: flex;
  gap: 0.8rem;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.5px;
  color: ${sg.colors.dark};
`;

export const ResolveIncidentContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrimaryDetailsContainer = styled.div`
  display: flex;
  padding-right: 16px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 24px 0px 24px;
`;

export const SecondaryDetailsContainer = styled.div`
  display: flex;
  padding-right: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px 24px 0px 24px;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const ContentDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Title = styled.div`
  color: #0e1742;
  font-size: 16px;
  font-family: Work Sans;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  //word-wrap: break-word;
`;

export const Text = styled.div`
  color: #666666;
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 400;
  line-height: 16px;
  //word-wrap: break-word;
`;

export const CommentContainer = styled.div`
  margin: 32px 24px 0px 24px;
  display: flex;
  flex-direction: column;
`;

export const CommentHeader = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
`;

export const TextArea = styled.textarea`
  all: unset;
  color: #666666;
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 400;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  padding: 12px 5px 12px 16px;
  max-height: 80px;
  overflow: auto;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  height: 44px;
  justify-content: flex-end;
  bottom: 24px;
  right: 24px;
`;

export const CancelButtonContainer = styled.div`
  width: 96px;
  height: 100%;
`;

export const CloseButton = styled(Button)`
  background-color: #e8e8e8;
  padding: 1.2rem 2rem;
  width: 9.6rem;
  height: 100%;
  border-radius: 8px;

  color: #717171;
  font-size: 1.6rem;
  font-family: Work Sans;
  font-weight: 500;
  line-height: 2rem;
`;

export const AreaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;        
  justify-content: flex-start;
  max-height: 50px;
  overflow-y: auto !important; 
  gap: 8px;               
  overflow: hidden;       
  padding: 8px;           
  box-sizing: border-box; 
  max-width: 100%; 
`;

export const AreaText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  background: #ccc;
  width: 150px;
  border-radius: 3px;
  text-align: center;
  font-size: 11px;
  font-family: Work Sans;
  font-weight: 400;
  line-height: 13px;
`;
