import styled from 'styled-components';
import { Button } from '../../../../components/Buttons/Button';
import { sg } from '../../../../styles/style-guide';

interface SolveIncidentContainerProps {
  evacuation: boolean;
}

export const SolveIncidentContainer = styled.div<SolveIncidentContainerProps>`
  position: relative;
  width: 950px;
  //height: 525px;
  height: ${({ evacuation }) => (evacuation ? '510px' : '419px')};
  //padding: 48px 51px 40px 34px;
  display: flex;
  flex-direction: column;
`;

export const SolveIncidentHeaderTitleContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 24px 0px 0px 24px
`;

export const SolveIncidentHeaderLabel = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.5px; 
  color: ${sg.colors.dark};
`;

export const SolveIncidentContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsContainer = styled.div`
  display: flex;
  padding-right: 16px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 24px 0px 24px
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px
`

export const ContentDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px
`

export const Title = styled.div`
  color: #0E1742;
  font-size: 16px;
  font-family: Work Sans;
  font-weight: 400;
  font-style: normal; 
  line-height: 20px;
  //word-wrap: break-word 
`;

export const Text = styled.div`
  color: #666666;
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 400;
  line-height: 16px;
  //word-wrap: break-word 
`

export const CommentContainer = styled.div`
  margin: 32px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  //gap: 8px
`

export const CommentHeader = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px
`

export const TextArea = styled.div`
  height: 122px;
  //height: 80px;
`

export const DetailsTextArea = styled.div`
  display: flex;
  border: solid 1px #4f5882;
  border-radius: 5px;
  padding: 12px 5px 12px 16px; 
  height: 122px;
  overflow-y: scroll
`

export const CharacterCounter = styled.div`
display: flex;
justify-content: flex-end;
height: 32px;
margin-top: 0px;

color: rgba(19, 18, 18, 0.70);
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 266.667% */ 
`

export const ButtonsContainer = styled.div`
  display: inline-flex;
  height: 44px;
  gap: 16px;
  justify-content: flex-end;
  margin: 24px 24px 24px 0px
`;

export const CancelButtonContainer = styled.div`
  width: 96px;
  height: 100%;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  padding: 1.2rem 2rem;
  width: 9.6rem;
  height: 100%;
  border-radius: 8px;
  
  border: 2px solid #2F4CDD;
  color: #2F4CDD;
  font-size: 1.6rem;
  font-family: Work Sans;
  font-weight: 500;
  line-height: 2rem;  
`;

export const SolveButtonContainer = styled.div`
  width: 111px;
  height: 100%;
`;

export const SolveButton = styled(Button)`
  background-color: #2F4CDD;
  padding: 12px 20px;
  height: 100%;
  width: 111px;
  border-radius: 8px;
  
  color: white;
  font-size: 16px;
  font-family: Work Sans;
  font-weight: 500;
  line-height: 20px; 
`;

export const SecondaryDetailsContainer = styled.div`
  display: flex;
  padding-right: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px 24px 0px 24px;
`;

export const AreaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;        
  justify-content: flex-start;
  max-height: 50px;
  overflow-y: auto !important; 
  gap: 8px;               
  overflow: hidden;       
  padding: 8px;           
  box-sizing: border-box; 
  max-width: 100%; 
`;

export const AreaText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  background: #ccc;
  width: 150px;
  border-radius: 3px;
  text-align: center;
  font-size: 11px;
  font-family: Work Sans;
  font-weight: 400;
  line-height: 13px;
`;