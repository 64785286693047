import React, { useState, useEffect } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { mixpanelTrack } from '../../../services/SystemMonitor';

import MachinesTable from './MachinesTable';
import { getObjectToStringMatrix } from '../../../utils/Convert';
import { useAuth, useTrackfyApi } from '../../../hooks';
import { MachinesSummaryProps } from '../types/MachinesSummaryTypes';
import { SummaryContainer } from '../../dailySummary/components/styled';
import { useAuthContext } from '../../../stores';

const MachinesSummaryReport: React.FC<MachinesSummaryProps> = (props: MachinesSummaryProps) => {
  const { detailHeader, setData, detailData } = props;

  const [currentStatus, setCurrentStatus] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const { generateMachinesSummaryApi } = useTrackfyApi();
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => {
    let mounted = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      mixpanelTrack(monitorAgent, 'Sumário de máquina', userName, authData, 'paginaSumarioMaquinas');

      generateMachinesSummaryApi(
        currentRequest.token,
        setData,
        setIsLoading,
        setIsEmpty,
        setCurrentStatus
      );
    }
    return () => {
      mounted = false;
      currentRequest.cancel('MachinesSummary request canceled by the user.');
    };
  }, []);

  return (
    <SummaryContainer>
      <MachinesTable
        tableHeaders={detailHeader}
        tableData={getObjectToStringMatrix(detailData.data)}
        loading={isLoading}
        isEmpty={isEmpty}
        idValues={detailData.idValues}
        setCurrentStatus={setCurrentStatus}
        currentStatus={currentStatus}
      />
    </SummaryContainer>
  );
};

export default MachinesSummaryReport;