import React, { useState } from 'react';

import { useAuthContext } from '../../../../stores/AuthContext';
import { useAuth } from '../../../../hooks/useAuth';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { IconLoading } from '../../../../components/IconLoading';
import QuestionMark from '../../../../Icons/QuestionMark';
import { CardExpanded } from './components/CardExpanded';

import * as S from './styles';
import { EmptyContainer } from '../../../../components/Table/styles';
import { useTextInsights } from './hooks/useTextInsights';

export const TextInsightsContent: React.FC = () => {
  const { informes, isLoading, companySelected, clientSelected } = useTextInsights();
  const [cardExpanded, setCardExpanded] = useState<boolean>(false);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  const openCard = () => {
    setCardExpanded(true);
    mixpanelTrack(monitorAgent, 'Insights de texto - Card Expandido', userName, authData, 'insightsTexto>cardExpandido');
  };

  const closeCard = () => {
    setCardExpanded(false);
  };

  if (clientSelected == 0)
    return (
      <S.TextInsightsCard>
        <EmptyContainer>
          <QuestionMark />
          <span>Para visualizar os dados, selecione um cliente ou empresa</span>
        </EmptyContainer>
      </S.TextInsightsCard>
    );

  return (
    <>
      <S.TextInsightsCard onClick={openCard}>
        {isLoading && <IconLoading customDivStyle={{ marginTop: '2em', marginLeft: '50%' }} />}
        {!isLoading &&
          informes.length > 0 &&
          informes.map((value) =>
            <>
              <S.TextInsightsContentBackground>
                {value.workingpermit} 
              </S.TextInsightsContentBackground>
              <S.TextInsightsContent>
                {value.inout}
              </S.TextInsightsContent>
                {value.almox.map((item, index: number) => 
                  index % 2 === 0 ? (
                    <S.TextInsightsContentBackground key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContentBackground>
                  ): (
                    <S.TextInsightsContent key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContent>
                  ))
                }
                {value.minilab.map((item, index: number) => 
                  index % 2 === 0 ? (
                    <S.TextInsightsContentBackground key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContentBackground>
                  ): (
                    <S.TextInsightsContent key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContent>
                  ))
                }
            </>
          )}
      </S.TextInsightsCard>

      {cardExpanded && informes.length > 0 && (
        <CardExpanded
          onClick={closeCard}
          content={
            informes.map((value) =>
            <>
              <S.TextInsightsContentBackground>
                {value.workingpermit} 
              </S.TextInsightsContentBackground>
              <S.TextInsightsContent>
                {value.inout}
              </S.TextInsightsContent>
                {value.almox.map((item, index: number) => 
                  index % 2 === 0 ? (
                    <S.TextInsightsContentBackground key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContentBackground>
                  ): (
                    <S.TextInsightsContent key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContent>
                  ))
                }
                {value.minilab.map((item, index: number) => 
                  index % 2 === 0 ? (
                    <S.TextInsightsContentBackground key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContentBackground>
                  ): (
                    <S.TextInsightsContent key={index + '_' + item}>
                      {item}
                    </S.TextInsightsContent>
                  ))
                }
            </>
          )}
        />
      )}
    </>
  );
};