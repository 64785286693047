import React, { createContext, ReactNode, useState, useContext } from 'react';
import moment from 'moment';

import { SelectOption } from '../../../components/Select/types';
import { parseToDateFormatted } from '../../../utils/DateTime';

type GeneralMovimentContextProps = {
  companySelected: SelectOption[];
  professionSelected: SelectOption[];
  initialDate: any;
  finalDate: any;
  date: any;
  setCompanySelected: (companie: SelectOption[]) => void;
  setProfessionSelected: (profession: SelectOption[]) => void;
  updatePeriod: (initial: any, final: any) => void;
  clearFilters: () => void;
  setDate: (value: any) => void;
};

type Props = {
  children: ReactNode;
};

const GeneralMovimentContext = createContext<GeneralMovimentContextProps>({} as GeneralMovimentContextProps);

export function GeneralMovimentContextProvider(props: Props) {
  const { children } = props;
  const [initialDate, setInitialDate] = useState<any>();
  const [finalDate, setFinalDate] = useState<any>();
  const [professionSelected, setProfessionSelected] = useState<SelectOption[]>([]);
  const [companySelected, setCompanySelected] = useState<SelectOption[]>([]);
  const [date, setDate] = useState(undefined);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const clearFilters = () => {
    setCompanySelected([]);
    setProfessionSelected([]);
    updatePeriod(moment(), moment());
    setDate(parseToDateFormatted(new Date(), new Date()));
  };

  const value = {
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
    date,
    setDate,
    clearFilters,
  };
  return <GeneralMovimentContext.Provider value={value}>{children}</GeneralMovimentContext.Provider>;
}

export function useGeneralMovimentContext() {
  const context = useContext(GeneralMovimentContext);

  if (typeof context === 'undefined') {
    throw new Error('GeneralMovimentContext must be used within an GeneralMovimentContext');
  }

  return context;
}
