import React, { useState } from 'react';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';

import { useAuth } from '../../../../hooks/useAuth';
import { useAuthContext } from '../../../../stores/AuthContext';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { usePresenceArea } from './hooks/usePresenceArea';
import PresenceAreaChartHeader from './components/PresenceAreaChartHeader';
import { CustomTooltip } from './components/CustomTooltip';
import { IconLoading } from '../../../../components/IconLoading';

import * as S from './styles';

const PresenceAreaChart: React.FC = () => {
  const { presenceAreaChartData, isLoading } = usePresenceArea();
  const [pointChanges, setPointChanges] = useState(0);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const ShowTooltip = (e: any) => {
    if (e.active) return <CustomTooltip tooltipProps={e.payload[0].name} />;

    if (e.active) return <CustomTooltip tooltipProps={e.payload[0].name} />;

    if (pointChanges != 0)
      mixpanelTrack(
        monitorAgent,
        `Produtividade - Tempo acumulado - ${pointChanges} iterações`,
        userName,
        authData,
		'Indic>ProdTempAcumulado'
      );

    return null;
  };

  const HandleMouseLeave = () => setPointChanges(0);

  const handleMouseEnter = () => setPointChanges(pointChanges + 1);

  return (
    <>
      <PresenceAreaChartHeader />
      <S.CardContent>
        {isLoading && <IconLoading />}
        {!isLoading && (
          <ResponsiveContainer width="100%" height="100%">
            <Treemap
              width={500}
              height={250}
              data={[presenceAreaChartData]}
              isAnimationActive={false}
              nameKey="name"
              dataKey="value"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={HandleMouseLeave}
            >
              <Tooltip content={ShowTooltip} />
            </Treemap>
          </ResponsiveContainer>
        )}
      </S.CardContent>
    </>
  );
};

export default PresenceAreaChart;
