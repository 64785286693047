import React from 'react';
import { DefaultIconProps } from './types';

export const FilterCancelIcon: React.FC<DefaultIconProps> = ({ fill, width, height, style }) => {
  return (
    <svg
      style={style}
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4096 20.7917L11.9996 21.7017C10.6896 22.5117 8.86957 21.6017 8.86957 19.9817V14.6317C8.86957 13.9217 8.46957 13.0117 8.05957 12.5117L4.21957 8.47168C3.70957 7.96168 3.30957 7.06172 3.30957 6.45172V4.13171C3.30957 2.92171 4.21957 2.01172 5.32957 2.01172H18.6696C19.7796 2.01172 20.6896 2.92174 20.6896 4.03174V6.25171C20.6896 7.06171 20.1796 8.07172 19.6796 8.57172"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6299 14.75C21.6299 15.64 21.3799 16.48 20.9399 17.2C20.1199 18.58 18.6099 19.5 16.8799 19.5C15.1499 19.5 13.6399 18.57 12.8199 17.2C12.3799 16.49 12.1299 15.64 12.1299 14.75C12.1299 12.13 14.2599 10 16.8799 10C19.4999 10 21.6299 12.13 21.6299 14.75Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1496 15.9905L15.6396 13.4805"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1301 13.5117L15.6201 16.0217"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6896 4.02002V6.23999C20.6896 7.04999 20.1796 8.06001 19.6796 8.57001L17.9196 10.12C17.5896 10.04 17.2396 10 16.8796 10C14.2596 10 12.1296 12.13 12.1296 14.75C12.1296 15.64 12.3796 16.48 12.8196 17.2C13.1896 17.82 13.6996 18.35 14.3196 18.73V19.07C14.3196 19.68 13.9196 20.49 13.4096 20.79L11.9996 21.7C10.6896 22.51 8.86957 21.6 8.86957 19.98V14.63C8.86957 13.92 8.45957 13.01 8.05957 12.51L4.21957 8.46997C3.71957 7.95997 3.30957 7.05001 3.30957 6.45001V4.12C3.30957 2.91 4.21957 2 5.32957 2H18.6696C19.7796 2 20.6896 2.91002 20.6896 4.02002Z"
        stroke={fill || '#ED3A3A'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};