import React from 'react';

import { useRankContext } from '../context/RankContext';
import { RankChartLabel } from './RankChartLabel';
import IconUsers from '../../../../../Icons/Users';
import { useAuth } from '../../../../../hooks';
import { Info } from '../../../../../components/Info';
import { getRankChartTooltipContent } from './RankChartTooltip';

import * as S from '../styles';

export const RankChartHeader: React.FC = () => {
  const { higherProductivity, lowerProductivity, average, isLoadingAverage } = useRankContext();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getRankChartTooltipContent(clientId);
  // const { isLoadingAverage } = useRank();

  /* const onSearch = async (value: string) => {
    setRankSearchValue(value);
  }; */

  return (
    <S.Header>
      <S.Titlecontainer>
        <IconUsers />
        <S.Title>Ranking de Produtividade</S.Title>
        <S.IconContainer>
           <Info 
            content={tooltipContent} 
            position={'left'} 
            burndown={false}
            screen={'Prod'}
            eventName={'RankProd'}/>
        </S.IconContainer>
      </S.Titlecontainer>

      <S.SummaryContainer>
        <S.RankSummaryContainer>
          <S.RankDescription>
            Maior <br /> produtividade
          </S.RankDescription>
          <RankChartLabel
            value={higherProductivity}
            isLoadingAverage={isLoadingAverage}
            percentage
          />
        </S.RankSummaryContainer>

        <S.RankSummaryContainer>
          <S.RankDescription>
            Menor <br /> produtividade
          </S.RankDescription>
          <RankChartLabel
            value={lowerProductivity}
            isLoadingAverage={isLoadingAverage}
            percentage
          />
        </S.RankSummaryContainer>

        <S.RankSummaryContainer>
          <S.AverageDescription>Média</S.AverageDescription>
          <RankChartLabel value={average} isLoadingAverage={isLoadingAverage} percentage />
        </S.RankSummaryContainer>
      </S.SummaryContainer>

      {/* <SearchContainer>
        <InputSearch
          id="rankSearchInput"
          placeholder="Pesquise por um recurso"
          onSearch={onSearch}
        />
      </SearchContainer> */}
    </S.Header>
  );
};
