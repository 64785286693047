import React, { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { getAreas } from '../../services/api/TrackfyService';
import { Area } from '../../types/Filters';
import { DropdownIndicator } from '../../components/MultiSelect/DropdownIndicator';
import { MultiSelectStyles } from '../../components/MultiSelect/styles';
import { MultiSelect } from '../../components/MultiSelect';
import { MultiSelectProps, MultiSelectType } from './types';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { useAuth } from '../../hooks';
import { useAuthContext } from '../../stores';

const AreaMultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
  const [areas, setAreas] = useState<MultiSelectType>({selectOptions:[], loading:true});
  const { itemsSelected, setItemsSelected, screen, eventName } = props;
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      getAreas(currentRequest.token, true)
        .then((result: AxiosResponse<any>) => {
          const options: SelectOption[] = result.data
			  .filter((item: Area) => item.name !== 'Sala capacetes')  
			  .map((area: Area) => {
				return { label: area.name, value: area.id ? area.id.toString() : '0' }
			  });

          setAreas({selectOptions: options, loading: false});
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getAreasApi request canceled by the user.');
    };
  }, []);

  const handleChange = (selected: any):void => {
    setItemsSelected(selected as SelectOption[])
    
    const label = selected.map((item:SelectOption) => item.label);

    if (label && label.length > 0)
      mixpanelTrack(monitorAgent, `${screen} - Area ${label} selecionada`, userName, authData, `${eventName}>filtArea${label}`);
  }

  return (
    <MultiSelect
      id='avrgtime-Company-select'
      name='avrgtime-Company-select'
      placeholder='Selecione uma área'
      options={areas.selectOptions}
      isLoading={areas.loading}
      dropdownIndicator={{DropdownIndicator}}
      styles={MultiSelectStyles}
      onChange={handleChange}
      currentValue={itemsSelected}
      isMulti={true}
    />
  );
};

export default AreaMultiSelect;