import React from 'react';

import { AuthData } from '../../../types';
import { MonitoredMap } from '../../../components/MonitoredMap';
import { MonitoredBuilding } from './MonitoredBuilding';
import { RealTimeMapComponents } from './RealTimeMapComponents';

type RealTimeContainerProps = {
  authData: AuthData | null | undefined;
  hasMap: boolean;
};

export const RealTimeComponentsContainer: React.FC<RealTimeContainerProps> = ({
  authData,
  hasMap,
}: RealTimeContainerProps) => {
  const mapComponents = (<RealTimeMapComponents key="RealTimeMapComponents" authData={authData} />)

  if (hasMap)
    return (
      <MonitoredMap
        childComponents={[mapComponents]}
        monitoredComponent="realtime"
		eventName='paginaTempoReal'
      />
    );

  return <MonitoredBuilding />;
};
