import React, { useEffect } from 'react';
import { Modal } from '../../../../components/Modal';
import { Textarea } from '../../../../components/Textarea';
import { useAuth } from '../../../../hooks';
import IconClock from '../../../../Icons/Clock';
import IconLocation from '../../../../Icons/Location';
import IconReason from '../../../../Icons/Reason';
import IconTickCircle from '../../../../Icons/TickCircle';
import IconUsers from '../../../../Icons/Users';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../stores';
import { useSolveIncident } from './hooks/useResolveIncident';
import { Info } from '../../../../components/Info';

import * as S from './styles';

type SolveIncidentProps = {
  isSolving: boolean;
  onClickToClose(): void;
  onClickToSolve(): void;
  collaborator: string;
  place: string;
  timestamp: string;
  reason: string;
  details?: string;
  id?: string;
  evacuatedAreas?: string;
  evacuationDuration?: number;
};

export const SolvingIcident: React.FC<SolveIncidentProps> = ({
  isSolving,
  onClickToClose,
  collaborator,
  place,
  timestamp,
  reason,
  id,
  onClickToSolve,
  details,
  evacuatedAreas,
  evacuationDuration
}: SolveIncidentProps) => {
  const { solvingIncident, handleSolveIncident, comment, handleCommentChange, characterCount, isDefined } =
    useSolveIncident(id);
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Log de Incidentes - Abriu popup para resolver incidente', userName, authData, 'logDeIncid>resolverIncid'), []);
  
  const tooltipContent = [{ content: 'Nesta tela você pode resolver um incidente, registrando informações sobre o mesmo. '
    + 'Uma vez solucionado o evento não aparecerá mais nos alertas do sistema' }];
    
  const convertSecondsToTime = (total: number):string => {
    const seconds = ( (total % 60).toFixed(0) ).padStart(2, "0");
    const minutes = ( (total / 60).toFixed(0) ).padStart(2, "0");
    
    const hoursRaw = ( (total / 60) / 60 );
    const hours = ( hoursRaw.toFixed(0) ).padStart(2, "0");
    
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <Modal visible={isSolving}>
      <S.SolveIncidentContainer evacuation={reason == "Evento de evacuação"}>
        <S.SolveIncidentHeaderTitleContainer>
          <IconTickCircle />
          <S.SolveIncidentHeaderLabel>Resolver incidente</S.SolveIncidentHeaderLabel>
		      <Info content={tooltipContent} position={'right'} burndown={false} screen={'logDeIncid'} eventName={'resolverIncid'} />
        </S.SolveIncidentHeaderTitleContainer>
        <S.SolveIncidentContent>
          <S.DetailsContainer>
            {(reason != "Evento de evacuação") &&
              <>
                <S.DetailItem>
                  <IconUsers fill="#808080" />
                  <S.ContentDetailItem>
                    <S.Title>Colaborador</S.Title>
                    <S.Text>{collaborator}</S.Text>
                  </S.ContentDetailItem>
                </S.DetailItem>
                <S.DetailItem>
                  <IconLocation stroke="#808080" />
                  <S.ContentDetailItem>
                    <S.Title>Local</S.Title>
                    <S.Text>{place}</S.Text>
                  </S.ContentDetailItem>
                </S.DetailItem>
              </>
            }
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário da emergência</S.Title>
                <S.Text>{timestamp}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
			{(reason == "Evento de evacuação") &&
              <S.DetailItem>
                <IconClock stroke="#808080" />
                <S.ContentDetailItem>
                  <S.Title>Duração</S.Title>
                  <S.Text>{evacuationDuration ? convertSecondsToTime(evacuationDuration) : "-"}</S.Text>
                </S.ContentDetailItem>
              </S.DetailItem>
            }
            <S.DetailItem>
              <IconReason stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Motivo do acionamento</S.Title>
                <S.Text>{reason}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.DetailsContainer>
		
			{(reason == "Evento de evacuação") &&
				<S.SecondaryDetailsContainer>
              <S.DetailItem>
                <IconLocation />
                <S.ContentDetailItem>
                  <S.Title>Áreas evacuadas</S.Title>
                  <S.AreaContainer>{evacuatedAreas ? 
                    (evacuatedAreas.split(";").map((area:string) => <S.AreaText>{area}</S.AreaText>)) : "-"}
                  </S.AreaContainer>
                </S.ContentDetailItem>
              </S.DetailItem>
            </S.SecondaryDetailsContainer>
          }
		  
          <S.CommentContainer>
            <S.CommentHeader>
              <S.Title>Comentário</S.Title>
              <S.Text>(obrigatório)</S.Text>
            </S.CommentHeader>
            <S.TextArea>
              <Textarea
                placeholder="Escreva como o incidente foi resolvido..."
                maxLength={500}
                //disabled={loadingComments || savingComment}
                value={comment}
                onChange={(value) => handleCommentChange(value)}
              />
            </S.TextArea>
            <S.CharacterCounter>{characterCount}/500</S.CharacterCounter>
          </S.CommentContainer>
          <S.ButtonsContainer>
            <S.CancelButtonContainer>
              <S.CloseButton
                title="Cancelar"
                onClick={onClickToClose}
                disabled={solvingIncident}
                loading={solvingIncident}
              />
            </S.CancelButtonContainer>
            <S.SolveButtonContainer>
              <S.SolveButton
                title="Resolver"
                disabled={!isDefined()}
                loading={solvingIncident}
                onClick={async () => {
                  await handleSolveIncident();
                  onClickToSolve();
                }}
              />
            </S.SolveButtonContainer>
          </S.ButtonsContainer>
        </S.SolveIncidentContent>
      </S.SolveIncidentContainer>
    </Modal>
  );
};
