import { useState } from 'react';
import { useAuth } from '../../../../../hooks/useAuth';
import { mixpanelTrack } from '../../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../../stores/AuthContext';

export const useCard = () => {
  const [cardExpanded, setCardClassName] = useState<boolean>(false);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleClickExpand = (e: MouseEvent) => {
    e.preventDefault();
    setCardClassName(!cardExpanded);
    if (!cardExpanded)
      mixpanelTrack(monitorAgent, 'TempoReal - Resumo aberto', userName, authData, 'TempoReal>Resumo');
  };

  return {
    cardExpanded,
    handleClickExpand,
  };
};
