import React from 'react';

import IconUsers from '../../../../../Icons/Users';
import { useAuth } from '../../../../../hooks';
import { getPresenceAreaTooltipContent } from './PresenceAreaInfoTooltip';
import { Info } from '../../../../../components/Info';

import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
  //margin-top: 0.9rem;
  margin-bottom: 2rem;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  
  /*& > svg {
    margin-right: 1.6rem;
  }*/
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const PresenceAreaChartHeader: React.FC = () => {
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getPresenceAreaTooltipContent(clientId);

  return(
    <Header>
      <Titlecontainer>
        <IconUsers />
        <Title>Tempo acumulado de presença por área</Title>
          <Info 
            content={tooltipContent} 
            position={'left'} 
            burndown={false}
            screen={'Prod'}
            eventName={'TempAcumulado'}
          />
      </Titlecontainer>
    </Header>
  )
};

export default PresenceAreaChartHeader;
