import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AddCollaboratorContainer } from '../AddCollaborator';
import { CollaboratorsTable } from '../CollaboratorsTable';
import { DeleteCollaborator } from '../DeleteCollaborator';
import { useCollaboratorsList } from './hooks/useCollaboratorsList';
import LinkCollaboratorsOptions from '../LinkCollaboratorsOptions';
import { Button } from '../../../../components/Buttons/Button';
import IconAdd from '../../../../Icons/Add';
import IconUsers from '../../../../Icons/Users';

import * as S from './styles'
import 'react-toastify/dist/ReactToastify.css'
import { Info } from '../../../../components/Info';
import { useAuth } from '../../../../hooks';

export const CollaboratorsList: React.FC = () => {
    const {isLoading, 
    linkCollaborators,
    isAdding, 
    setIsAdding, 
    handleCloseAddCollaborador, 
    isDeleting,
    handleCloseDeleteCollaborador,
    selectedRow,
    handleCloseAddCollaboradorAndUpdateList,
    handleCloseDeleteCollaboradorAndUpdateList,
    getLinkCollaboratorsTooltipContent,
    } = useCollaboratorsList();
    const { getClientId } = useAuth();
    const clientId = getClientId();
    const tooltipContent = getLinkCollaboratorsTooltipContent(clientId);

    return(
        <S.CollaboratorsListContainer>
            <ToastContainer/>
            <S.CollaboratorsListHeader>
                <S.CollaboratorsListHeaderTitleContainer>
                    <IconUsers/>
                    <S.CollaboratorsListHeaderLabel>Resumo das Tags vinculadas aos Colaboradores</S.CollaboratorsListHeaderLabel>
                    <S.IconContainer>
                        <Info 
                            content={tooltipContent} 
                            position={'right'} 
                            burndown={false}
                            screen={'Vinculacao'}
                            eventName={''}
                        />
                    </S.IconContainer>
                </S.CollaboratorsListHeaderTitleContainer>
                <S.NewCollaboratorButtonContainer>
                    <Button
                        title='Novo colaborador'
                        icon={<IconAdd fill="#FFF" height="20px" width="20px" />}
                        onClick={() => setIsAdding(true)}
                    />
                </S.NewCollaboratorButtonContainer>
            </S.CollaboratorsListHeader>
            <LinkCollaboratorsOptions/>
            <CollaboratorsTable detailData={linkCollaborators} loading={isLoading} empty={false}/>

            {isAdding && (
                    <AddCollaboratorContainer 
                    isActive={isAdding} 
                    setIsAdding={setIsAdding} 
                    onClickToClose={handleCloseAddCollaborador}
                    onClickToSave={handleCloseAddCollaboradorAndUpdateList}
                    />
            )}
            {isDeleting && (
                <DeleteCollaborator 
                tagAlias={selectedRow.tagAlias}
                isDeleting={isDeleting}
                onClickToClose={handleCloseDeleteCollaborador}
                collaborator={selectedRow.collaborator}
                onClickToDelete={handleCloseDeleteCollaboradorAndUpdateList}
                />
            )}
        </S.CollaboratorsListContainer>
    )
}