import React from 'react';

import { useInOutContext } from '../context/InOutContext';
import { useInOut } from '../hooks/UseInOut';
import { XlsxExportButton } from '../../../../../components/Buttons/XlsxExportButton';

export const InOutExportButton: React.FC = () => {
    const { inOutValues } = useInOutContext();
    const { exportDataToXlsx } = useInOut();
    return <XlsxExportButton data={inOutValues} 
                exportData={exportDataToXlsx} 
                fileName='Chegadas_Saídas'
                screen={`Indicadores - Produtividade - Chegadas/Saídas`}
                eventName={'Indic>abaProdInOut'}/>
};