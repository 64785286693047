import React from 'react';

import { useAbsenteeism } from './hooks/useAbsenteeism';
import { Info } from '../../../../../components/Info';
import { getAbsenteeismTooltipContent } from './SummaryIndicatorsTooltip';
import { useAuth } from '../../../../../hooks';

import * as S from './styles';

export const Absenteeism: React.FC = () => {
  const { indicator } = useAbsenteeism();
  const { getClientId } = useAuth();
  const clientId = getClientId();
  const tooltipContent = getAbsenteeismTooltipContent(clientId);
  
  return (
    <S.Card>
      <S.CardHeader>Absenteísmo: </S.CardHeader>
      <S.CardContent>{indicator.value}</S.CardContent>
      <S.IconContent>
         <Info 
          content={tooltipContent} 
          position={'left'} 
          burndown={false}
          screen={'TempoReal'}
          eventName={'ResAbsen'}
        />
      </S.IconContent>
    </S.Card>
  );
};