import React from 'react';

import { useAuthContext } from '../../stores/AuthContext';
import { ContentContainer } from '../../components/PageStructure/styles';
import { TimelineContextProvider } from './collaboratorTimeline/context/CollaboratorTimelineContext';
import { MovimentContextProvider } from './context/MovimentContext';
import MovimentOptions from './MovimentOptions';
import DynamicComponentsFactory from '../../components/DynamicComponents/DynamicComponentsFactory';

import './FullCallendar/FullCallendar.css';
import { useAuth } from '../../hooks';
import { GeneralMovimentContextProvider } from './context/GeneralMovimentContext';
import { GeneralMovimentExport } from './GeneralMoviment';

const Moviment: React.FC = () => {
  const { authData } = useAuthContext();
  const { getClientId } = useAuth();
  const charts = authData ? authData.components.charts : [];
  const clientId = getClientId();

  return (
  <ContentContainer value="">
    <MovimentContextProvider>
      <TimelineContextProvider>
        <MovimentOptions />
        {(clientId == 11 || clientId == 15) && <DynamicComponentsFactory charts={charts} component='areasTimeDivision'/>}
        <DynamicComponentsFactory charts={charts} component='displacementBtwnAreas'/>
        <DynamicComponentsFactory charts={charts} component='heatmap'/>
        <DynamicComponentsFactory charts={charts} component='movCalendar'/>
        <DynamicComponentsFactory charts={charts} component='movTimeline'/>
        {(getClientId() == 18) &&
          <GeneralMovimentContextProvider>
            <GeneralMovimentExport />
          </GeneralMovimentContextProvider>
        }
      </TimelineContextProvider>
    </MovimentContextProvider>
  </ContentContainer>
)
};

export default Moviment;
