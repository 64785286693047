import styled from 'styled-components';

export const MultiOptionsCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 1.6rem;
  width: 100%;
  padding: 2.4rem;
  gap: 3.2rem;
`;

export const OptionsCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  align-items: center;
  color: #0e1742;
  font-family: Work Sans;
`;

export const OptionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2.4rem;
  flex-wrap: wrap;
`;

export const SimpleContainer = styled.div`
  min-width: 25rem;
  position: static;
  z-index: 99999999;
  width: clamp(25rem, 18vw, 50rem);
`;
