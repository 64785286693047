import React from 'react';
import { MonitoringIconVuesax } from '../../../../Icons/Monitoring-Vuesax';
import { VerticalBarsIcon } from '../../../../Icons/VerticalBars';
import { iconConfig } from '../../../../config';
import IconCalendar from '../../../../Icons/Calendar';
import IconLocationSearch from '../../../../Icons/LocationSearch';
import { IconClientCompanyRegister } from '../../../../Icons/ClientCompanyRegister';
import { DefaultIconProps } from '../../../../Icons/types';
import { SummaryIcon } from '../../../../Icons/Summary';
import { DangerIcon } from '../../../../Icons/Danger';
import { ClipboardDangerIcon } from '../../../../Icons/ClipboardDanger';
import { PresentionChartIcon } from '../../../../Icons/PresentionChart';
import { UserAddIcon } from '../../../../Icons/UserAdd';
import { UsersIconVuesax } from '../../../../Icons/Users-Vuesax';
import { MessageQuestionIcon } from '../../../../Icons/MessageQuestion';
import { IconStatus } from '../../../../Icons/Status';
import IconHome from '../../../../Icons/Home';
import { TruckIcon } from '../../../../Icons/Trucks';
import IconInfo from '../../../../Icons/Info';
import { ReportsMenuIcon } from '../../../../Icons/Menu/Reports';

type PagesIcons = Record<keyof typeof iconConfig, React.FC<DefaultIconProps>>;

export const pagesIcons: PagesIcons = {
  agilean: (props) => <IconCalendar {...props} />,
  calibration: (props) => <IconLocationSearch {...props} />,
  clientregistration: (props) => <IconClientCompanyRegister {...props} />,
  collaboratorsLink: (props) => <UserAddIcon {...props} />,
  curves: (props) => <VerticalBarsIcon {...props} />,
  dashboard: (props) => <MonitoringIconVuesax {...props} />,
  displacement: (props) => <VerticalBarsIcon {...props} />,
  emergency: (props) => <DangerIcon {...props} />,
  incidents: (props) => <ClipboardDangerIcon {...props} />,
  indicators: (props) => <VerticalBarsIcon {...props} />,
  insights: (props) => <PresentionChartIcon {...props} />,
  machinesSummary: (props) => <VerticalBarsIcon {...props} />,
  newpassword: (props) => <VerticalBarsIcon {...props} />,
  onePage: (props) => <VerticalBarsIcon {...props} />,
  realtime: (props) => <MonitoringIconVuesax {...props} />,
  reports: (props) => <VerticalBarsIcon {...props} />,
  scannerstatus: (props) => <IconStatus {...props} />,
  summary: (props) => <SummaryIcon {...props} />,
  support: (props) => <MessageQuestionIcon {...props} />,
  textInsights: (props) => <VerticalBarsIcon {...props} />,
  users: (props) => <UsersIconVuesax {...props} />,
  homepage: (props) => <IconHome {...props} />,
  infleet: (props) => <TruckIcon {...props} />,
  logs:(props) => <IconInfo {...props} />,
  resourcessummary: (props) => <ReportsMenuIcon {...props} />,
};
