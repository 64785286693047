import React, { ReactElement } from 'react';
import { MapProvider, Map, MapLayerMouseEvent } from 'react-map-gl';

import { useMap } from './hooks/useMap';
import { MapLoading } from './components/MapLoading';

type MonitoredMapProps = {
  childComponents: ReactElement[];
  monitoredComponent: string;
  height?: string;
  eventName: string;
};

export const MonitoredMap: React.FC<MonitoredMapProps> = (props: MonitoredMapProps) => {
  const { childComponents, monitoredComponent, height, eventName } = props;
  const { latitudeRef, longitudeRef, initializeMap, mapStyle, zoomRef } = useMap(monitoredComponent, eventName);

  if (!initializeMap) return <MapLoading />;
  const mapHeight = height ? height : '100vh';

  return (
    <div>
      <MapProvider>
        <Map
          id="mymap"
          initialViewState={{
            longitude: longitudeRef.current,
            latitude: latitudeRef.current,
            zoom: zoomRef.current,
          }}
          style={{ height: mapHeight }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
          mapStyle={mapStyle}
          onClick={(e: MapLayerMouseEvent) => {
            if(monitoredComponent == "realtime") console.log(e.lngLat);
          }}
        >
         {childComponents}
        </Map>
      </MapProvider>
    </div>
  );
};