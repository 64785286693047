import React, { useEffect } from 'react';
import { Modal } from '../../../../components/Modal';
import { useAuth } from '../../../../hooks';
import IconClock from '../../../../Icons/Clock';
import IconLocation from '../../../../Icons/Location';
import IconReason from '../../../../Icons/Reason';
import IconTickCircle from '../../../../Icons/TickCircle';
import IconUsers from '../../../../Icons/Users';
import { mixpanelTrack } from '../../../../services/SystemMonitor';
import { useAuthContext } from '../../../../stores';

import * as S from './styles';
import { FileIconVuesax } from '../../../../Icons/File-Vuesax';

type IncidentDetailsProps = {
  isDetails: boolean;
  onClickToClose(): void;
  collaborator: string;
  place: string;
  timestamp: string;
  reason: string;
  id?: string;
  comment?: string;
  details?: string;
  solvedBy?: string;
  date?: string;
  evacuatedAreas?: string;
  evacuationDuration?: number;
};

export const IncidentDetails: React.FC<IncidentDetailsProps> = ({
  isDetails,
  onClickToClose,
  collaborator,
  place,
  timestamp,
  reason,
  comment,
  solvedBy,
  date,
  evacuatedAreas,
  evacuationDuration,
}: IncidentDetailsProps) => {
  const { authData, monitorAgent } = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => mixpanelTrack(monitorAgent, 'Log de Incidentes - Detalhes dos incidentes', userName, authData, 'logDeIncid>Detalhes'), [])

  const convertSecondsToTime = (total: number):string => {
    const seconds = ( (total % 60).toFixed(0) ).padStart(2, "0");
    const minutes = ( (total / 60).toFixed(0) ).padStart(2, "0");
  
    const hoursRaw = ( (total / 60) / 60 );
    const hours = ( hoursRaw.toFixed(0) ).padStart(2, "0");
  
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <Modal visible={isDetails}>
      <S.ResolveIncidentContainer evacuation={reason == "Evento de evacuação"}>
        <S.ResolveIncidentHeaderTitleContainer>
          <S.ResolveIncidentHeaderLabel>
            <FileIconVuesax />
            Detalhes do incidente
          </S.ResolveIncidentHeaderLabel>
        </S.ResolveIncidentHeaderTitleContainer>
        <S.ResolveIncidentContent>
          <S.PrimaryDetailsContainer>
			{(reason != "Evento de evacuação") &&
				<>
					<S.DetailItem>
					  <IconUsers fill="#808080" />
					  <S.ContentDetailItem>  
						<S.Title>Colaborador</S.Title>
						<S.Text>{collaborator}</S.Text>
					  </S.ContentDetailItem>
					</S.DetailItem>
					<S.DetailItem>
					  <IconLocation stroke="#808080" />
					  <S.ContentDetailItem>
						<S.Title>Local</S.Title>
						<S.Text>{place}</S.Text>
					  </S.ContentDetailItem>
					</S.DetailItem>
				</>
			}
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário da emergência</S.Title>
                <S.Text>{timestamp}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
			{(reason == "Evento de evacuação") &&
              <S.DetailItem>
                <IconClock stroke="#808080" />
                <S.ContentDetailItem>
                  <S.Title>Duração</S.Title>
                  <S.Text>{evacuationDuration ? convertSecondsToTime(evacuationDuration) : "-"}</S.Text>
                </S.ContentDetailItem>
              </S.DetailItem>
            }
            <S.DetailItem>
              <IconReason stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Motivo do acionamento</S.Title>
                <S.Text>{reason}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.PrimaryDetailsContainer>
		
		  {(reason == "Evento de evacuação") &&
			<S.SecondaryDetailsContainer>
				<S.DetailItem>
					<IconLocation />
					<S.ContentDetailItem>
					  <S.Title>Áreas evacuadas</S.Title>
					  <S.AreaContainer>{evacuatedAreas ? 
						(evacuatedAreas.split(";").map((area:string) => <S.AreaText>{area}</S.AreaText>)) : "-"}
					  </S.AreaContainer>
					</S.ContentDetailItem>
				</S.DetailItem>
			</S.SecondaryDetailsContainer>
		  }
		  
          <S.SecondaryDetailsContainer>
            <S.DetailItem>
              <IconClock stroke="#808080" />
              <S.ContentDetailItem>
                <S.Title>Horário de registro da resolução</S.Title>
                <S.Text>{date}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
            <S.DetailItem>
              <IconTickCircle fill="#808080" />
              <S.ContentDetailItem>
                <S.Title>Resolvido Por</S.Title>
                <S.Text>{solvedBy}</S.Text>
              </S.ContentDetailItem>
            </S.DetailItem>
          </S.SecondaryDetailsContainer>

          <S.CommentContainer>
            <S.CommentHeader>
              <S.Title>Comentário</S.Title>
            </S.CommentHeader>
            <S.TextArea disabled={true}>{comment}</S.TextArea>
          </S.CommentContainer>
          <S.ButtonsContainer>
            <S.CancelButtonContainer>
              <S.CloseButton
                title="Fechar"
                onClick={onClickToClose}
                //disabled={resolvingIncident}
                //loading={resolvingIncident}
              />
            </S.CancelButtonContainer>
          </S.ButtonsContainer>
        </S.ResolveIncidentContent>
      </S.ResolveIncidentContainer>
    </Modal>
  );
};
