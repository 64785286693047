import { useState } from 'react';
import { useAuth } from '../../../hooks';
import { mixpanelTrack } from '../../../services/SystemMonitor';
import { useAuthContext } from '../../../stores/AuthContext';

export const useItem = () => {
  const [itemExpanded, setItemExpanded] = useState<boolean>(false);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();

  const handleClickExpand = (questionNumber: number) => {
    setItemExpanded(!itemExpanded);
	  
	if (itemExpanded == false)
      mixpanelTrack(monitorAgent, `FaQ - item ${questionNumber} aberto`, userName, authData, `Ajuda>pergunta${questionNumber}`);
  };

  return {
    itemExpanded,
    handleClickExpand,
  };
};